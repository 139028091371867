import React from 'react';
import {createRoot} from 'react-dom/client';
import {App} from './App';
import './index.css';
import {initializeSentry} from './lib/services/SentryTracking';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

initializeSentry();

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
