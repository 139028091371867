import {ApolloProvider} from '@apollo/client';
import {CacheProvider} from '@emotion/react';
import {Settings} from 'luxon';
import React, {useState} from 'react';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import {initializeStore} from './lib/redux/store';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import {SnackbarGenericComponent} from './components/functional/SnackbarComponent';
import i18n from './i18n';
import {LOCAL_KEY_TOKEN, SESSION_KEY_APP_STATE} from './lib/constants';
import {loadLocalData, loadSessionData} from './lib/utils/storage';
import createEmotionCache from './styles/createEmotionCache';
import {InternalApp} from './InternalApp';
import {routes} from './routes';
import {ThemeSwitcher} from './components/ThemeSwitcher';
import {loadDevMessages, loadErrorMessages} from '@apollo/client/dev';
import {TitleProvider} from './lib/contexts/TitleContext';
import {useApolloClient} from './lib/apolloClient';
import {Preloader} from './components/structure/Preloader';
import {useAppSelector} from './lib/redux/hooks';

// MUI X PRO license
import {LicenseInfo} from '@mui/x-license';
LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_PRO_LICENSE || '');

// Apollo error messages (only in dev environment)
if (process.env.NODE_ENV === 'development') {
    loadDevMessages();
    loadErrorMessages();
}

// Set the global timezone and local (Luxon)
Settings.defaultLocale = 'de';
Settings.defaultZone = 'Europe/Berlin';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// Redux store
const store = initializeStore(loadSessionData(SESSION_KEY_APP_STATE));

export const App: React.FC = () => {
    const [authToken, setAuthToken] = useState(loadLocalData(LOCAL_KEY_TOKEN));
    const {apolloClient, graphQlErrors, networkError} = useApolloClient({
        authToken,
        store,
    });

    // Initialize the router
    const HydrateFallbackComponent: React.FC = () => {
        const riffThemeMode = useAppSelector((state) => state.theme.mode);
        return (
            <div
                style={{
                    height: '100vh',
                    backgroundColor: riffThemeMode === 'dark' ? '#000' : '#FFF',
                }}
            >
                <Preloader fullHeight={true} isWhite={riffThemeMode === 'dark'} />
            </div>
        );
    };

    const router = createBrowserRouter([
        {
            element: (
                <InternalApp setNewAuthToken={setAuthToken} graphQlErrors={graphQlErrors} networkError={networkError} />
            ),
            children: routes,
            HydrateFallback: HydrateFallbackComponent,
        },
    ]);

    return (
        <Provider store={store}>
            <ApolloProvider client={apolloClient}>
                <CacheProvider value={clientSideEmotionCache}>
                    <ThemeSwitcher>
                        <I18nextProvider i18n={i18n}>
                            <TitleProvider>
                                <RouterProvider router={router} />
                                <SnackbarGenericComponent />
                            </TitleProvider>
                        </I18nextProvider>
                    </ThemeSwitcher>
                </CacheProvider>
            </ApolloProvider>
        </Provider>
    );
};
