import {combineReducers} from '@reduxjs/toolkit';
import {AppState} from '../../types/redux';

// Import all the slice reducers
import userReducer from './slices/userSlice';
import themeReducer from './slices/themeSlice';
import snackbarReducer from './slices/snackbarSlice';
import uploadImageReducer from './slices/uploadImageSlice';
import uploadFileReducer from './slices/uploadFileSlice';
import editImageMetaDataReducer from './slices/editImageMetaDataSlice';
import editFileMetaDataReducer from './slices/editFileMetaDataSlice';
import editPaymentDataReducer from './slices/editPaymentDataSlice';

// Use combineReducers without an explicit type to avoid reducer/state type mismatch
export const rootReducer = combineReducers({
    user: userReducer,
    theme: themeReducer,
    snackbar: snackbarReducer,
    uploadImage: uploadImageReducer,
    uploadFile: uploadFileReducer,
    editImageMetaData: editImageMetaDataReducer,
    editFileMetaData: editFileMetaDataReducer,
    editPaymentData: editPaymentDataReducer,
});

// Export the RootState type
export type RootState = ReturnType<typeof rootReducer>;

// This ensures the root reducer matches your AppState type
// Add this line if you need to maintain compatibility with existing AppState type
const _typeCheck: AppState = {} as RootState;
