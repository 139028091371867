import React from 'react';
import {Box, CircularProgress} from '@mui/material';
import {colors} from '../../styles/theme';

interface PreloaderProps {
    fullHeight?: boolean;
    minWidth?: number | string;
    minHeight?: number | string;
    isWhite?: boolean;
}

export const Preloader: React.FC<PreloaderProps> = ({
    minWidth = 50,
    minHeight = 50,
    fullHeight = false,
    isWhite = false,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                minWidth: minWidth,
                minHeight: fullHeight ? '100%' : minHeight,
                height: fullHeight ? '100%' : 'auto',
                alignItems: 'center',
                justifyContent: 'center',
                lineHeight: 0,
                color: isWhite ? colors.white : colors.turquoise50,
                flex: fullHeight ? 1 : 'none',
            }}
        >
            <CircularProgress color={'inherit'} />
        </Box>
    );
};
