import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EditFileMetaDataState} from '../../../types/redux';

const initialState: EditFileMetaDataState = {
    isOpen: false,
    file: null,
    fileIndex: null,
    isMediaFile: false,
    paragraphType: null,
    paragraphIndex: null,
    refetchQuery: null,
};

export const editFileMetaDataSlice = createSlice({
    name: 'editFileMetaData',
    initialState,
    reducers: {
        setFileMetaData: (state, action: PayloadAction<EditFileMetaDataState>) => {
            return {...action.payload};
        },
        resetFileMetaData: () => {
            return initialState;
        },
    },
});

// Export the action creators
export const {setFileMetaData, resetFileMetaData} = editFileMetaDataSlice.actions;

// Export the reducer
export default editFileMetaDataSlice.reducer;
