import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserState, UserStatus} from '../../../types/redux';
import {Location} from 'history';
import {AuthenticationRoleEnum} from '../../../types/graphqlTypes';

export const initialUserState: UserState = {
    authToken: null,
    status: UserStatus.ANONYMOUS,
    errorMessage: null,
    userId: null,
    userName: null,
    userEmail: null,
    role: AuthenticationRoleEnum.Anonymous,
    redirectLocation: null,
    language: null,
    fullAccess: false,
    hasAcceptedThirdPartyContent: false,
    accountHide: null,
    accountMessage: null,
    showAdminOptions: false,
    showRootOptions: false,
    avatar: null,
};

// Define types for action payloads
interface LoginSuccessPayload {
    authToken: string | null;
    userId: string;
    [key: string]: any; // To allow for additional properties
}

export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        loginAttempt: (state, action: PayloadAction<Location | undefined>) => {
            state.redirectLocation = action.payload ?? null;
            state.errorMessage = null;
            state.status = UserStatus.LOGIN_IN_PROGRESS;
        },
        loginSuccess: (state, action: PayloadAction<LoginSuccessPayload>) => {
            state.errorMessage = null;
            state.authToken = action.payload.authToken;
            state.userId = action.payload.userId;
            state.status = UserStatus.LOGGED_IN;

            // Copy any other properties from payload
            Object.keys(action.payload).forEach((key) => {
                if (key !== 'type') {
                    (state as any)[key] = action.payload[key];
                }
            });
        },
        loginError: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.status = UserStatus.LOGIN_FAILED;
        },
        logout: () => {
            return {
                ...initialUserState,
                status: UserStatus.LOGGED_OUT,
            };
        },
        sessionExpired: () => {
            return {
                ...initialUserState,
                status: UserStatus.SESSION_EXPIRED,
            };
        },
    },
});

// Export the action creators
export const {loginAttempt, loginSuccess, loginError, logout, sessionExpired} = userSlice.actions;

// Export the reducer
export default userSlice.reducer;
