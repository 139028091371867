import {gql} from '@apollo/client';
import {FRAGMENT_IMAGE_SRC} from './fragmentsImage';

export const FRAGMENT_USER_MAIN = gql`
    fragment FragmentUserMain on User {
        id
        gender
        title
        firstName
        middleName
        lastName
        fullName
        nickName
        avatar {
            ...FragmentImageSrc
        }
    }
    ${FRAGMENT_IMAGE_SRC}
`;

export const FRAGMENT_USER_CONTRIBUTOR = gql`
    fragment FragmentUserContributor on Contributor {
        user {
            ...FragmentUserMain
        }
        function
        custom
    }
    ${FRAGMENT_USER_MAIN}
`;

export const FRAGMENT_USER_PROFILE = gql`
    fragment FragmentUserProfile on User {
        profile {
            hasAccess
            purchases {
                id
                state
                productType
                productTitle
                productUrl
                datePurchased
                purchaseNumber
                price
                priceRaised
                voluntary
                payment {
                    id
                    provider
                    uid
                    type
                    identifier
                    currency
                    price
                    priceFormatted
                    fee
                    vat
                    country
                    dateCreated
                    dateImported
                    dateBooked
                    successBooked
                    dateRefund
                    refund
                    datePayout
                    state
                    invoiceNumber
                    invoiceUrl
                    note
                    owner {
                        id
                        gender
                        title
                        firstName
                        middleName
                        lastName
                        fullName
                        nickName
                        emails(only_main: false, only_public: false) {
                            id
                            email
                        }
                    }
                }
            }
            flatrates {
                id
                uid
                state
                title
                type
                price
                dateStart
                dateEnd
                dateCancelled
                payments {
                    id
                    provider
                    uid
                    type
                    identifier
                    currency
                    price
                    priceFormatted
                    fee
                    vat
                    country
                    dateCreated
                    dateImported
                    dateBooked
                    successBooked
                    dateRefund
                    refund
                    datePayout
                    state
                    invoiceNumber
                    invoiceUrl
                    note
                    owner {
                        id
                        gender
                        title
                        firstName
                        middleName
                        lastName
                        fullName
                        nickName
                        emails(only_main: false, only_public: false) {
                            id
                            email
                        }
                    }
                }
            }
            subscriptions {
                id
                uid
                state
                productTitle
                productType
                price
                dateStart
                dateEnd
                dateCancelled
                payments {
                    id
                    provider
                    uid
                    type
                    identifier
                    currency
                    price
                    priceFormatted
                    fee
                    vat
                    country
                    dateCreated
                    dateImported
                    dateBooked
                    successBooked
                    dateRefund
                    refund
                    datePayout
                    state
                    invoiceNumber
                    invoiceUrl
                    note
                    owner {
                        id
                        gender
                        title
                        firstName
                        middleName
                        lastName
                        fullName
                        nickName
                        emails(only_main: false, only_public: false) {
                            id
                            email
                        }
                    }
                }
            }
        }
    }
`;
