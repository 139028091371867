import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {
    Avatar,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, {memo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {NavLink} from 'react-router-dom';
import {
    ArticlesIcon,
    AuditIcon,
    CashRegisterIcon,
    DashboardIcon,
    EventsIcon,
    FilesIcon,
    ImagesIcon,
    LogoutIcon,
    MagazinesIcon,
    MediaFilesIcon,
    NewslettersIcon,
    PagesIcon,
    PointsIcon,
    SocialLinksIcon,
    TagsIcon,
    TeamsIcon,
    TutorialsIcon,
    UnmaskIcon,
    UsersIcon,
} from '../../../assets/NavigationIcons';
import {LogoRIcon} from '../../../assets/UiIcons';
import {LOCAL_KEY_TOKEN, LOCAL_KEY_UNMASK} from '../../../lib/constants';
import {
    ROUTE_ARTICLES,
    ROUTE_ARTICLES_LANG,
    ROUTE_AUDIT,
    ROUTE_CHANNELS,
    ROUTE_DASHBOARD,
    ROUTE_EVENTS_LANG,
    ROUTE_FILES_LANG,
    ROUTE_IMAGES,
    ROUTE_MEDIA_FILES_LANG,
    ROUTE_NEWSLETTER,
    ROUTE_PAGES,
    ROUTE_PAGES_LANG,
    ROUTE_PAYMENTS,
    ROUTE_PAYMENTS_LANG,
    ROUTE_POINTS_LANG,
    ROUTE_SEARCH_USERS,
    ROUTE_SOCIAL_LINKS,
    ROUTE_TAGS,
    ROUTE_TEAMS,
    ROUTE_TUTORIALS,
    ROUTE_USER,
} from '../../../lib/utils/router';
import {loadLocalData} from '../../../lib/utils/storage';
import {colors} from '../../../styles/theme';
import {AppState, UserState} from '../../../types/redux';
import {Preloader} from '../Preloader';
import {BugReport} from '@mui/icons-material';
import * as Sentry from '@sentry/react';
import {useUnmasquerade} from '../../functional/Masquerade';
import {useAuth} from '../../../lib/hooks/useAuth';
import {log} from '../../../lib/utils/logger';
import {initializeStore} from '../../../lib/redux/store';
import {setTheme} from '../../../lib/redux/slices/themeSlice';
import {useAppDispatch, useAppSelector} from '../../../lib/redux/hooks';

const DrawerContent: React.FC = memo(() => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const [authToken, setAuthToken] = useState(loadLocalData(LOCAL_KEY_TOKEN));
    const {logout} = useAuth(setAuthToken);

    const MemoizedLogoRIcon = memo(LogoRIcon);

    const user: UserState = useSelector<AppState, UserState>((state) => state.user);
    const showAdminOptions = user.showAdminOptions;

    const getIconColor = (currentPath: string, route: string) => {
        return currentPath.indexOf(route) >= 0 ? 'primary' : 'disabled';
    };

    const styles = {
        listItem: {
            px: 0,
            [theme.breakpoints.up('md')]: {
                px: 3,
            },
        },
    };

    const [isLoading, setIsLoading] = useState(false);
    const handleLogout = () => {
        if (!isLoading) {
            setIsLoading(true);
            logout().then(() => {
                setIsLoading(false);
                log('logout successful', authToken);
            });
        }
    };

    const isMasqueraded = !!loadLocalData(LOCAL_KEY_UNMASK);

    const store = initializeStore();
    const riffThemeMode = useAppSelector((state) => state.theme.mode) ?? 'light';
    const handleThemeSwitch = () => {
        if (store) {
            let newMode = 'light';
            if (riffThemeMode === 'light') {
                newMode = 'dark';
            }
            dispatch(
                setTheme({
                    mode: newMode,
                    isUserPreference: true,
                }),
            );
        }
    };

    // SENTRY: feedback custom button handler
    Sentry.setUser({
        id: user?.userId ?? '',
        fullName: user?.userName ?? user?.userId ?? '',
        email: user?.userEmail ?? '',
    });
    const handleSentryFeedback = async () => {
        const feedbackInstance = Sentry.getFeedback();
        if (feedbackInstance) {
            const form = await feedbackInstance.createForm({});
            form.appendToDom();
            form.open();
        }
    };

    return (
        <List
            sx={{
                maxHeight: '100%',
                overflow: 'auto',
                px: 0,
            }}
        >
            <ListItem sx={styles.listItem}>
                <ListItemIcon
                    sx={{
                        justifyContent: 'center',
                        [theme.breakpoints.down('xs')]: {
                            display: 'none',
                        },
                    }}
                >
                    <MemoizedLogoRIcon color={'primary'} />
                </ListItemIcon>
            </ListItem>

            <NavLink to={`${ROUTE_USER}/de/${user.userId}`} key={`${ROUTE_USER}/de`}>
                <ListItem sx={styles.listItem}>
                    <ListItemAvatar sx={{display: 'flex', justifyContent: 'center', mt: 3}}>
                        <Avatar
                            alt={''}
                            src={user.avatar ?? ''}
                            sx={{
                                width: 32,
                                height: 32,
                                border: getIconColor(location.pathname, ROUTE_USER) === 'primary' ? '3px solid' : '',
                                borderColor: colors.turquoise50,
                            }}
                        />
                    </ListItemAvatar>
                </ListItem>
            </NavLink>

            <NavLink to={`${ROUTE_PAGES_LANG}/de`} key={`${ROUTE_PAGES_LANG}/de`}>
                <ListItem sx={styles.listItem}>
                    <ListItemIcon
                        sx={{
                            justifyContent: 'center',
                            paddingTop: theme.spacing(3),
                            cursor: 'pointer',
                        }}
                    >
                        <PagesIcon color={getIconColor(location.pathname, ROUTE_PAGES)} />
                    </ListItemIcon>
                </ListItem>
            </NavLink>

            <NavLink to={`${ROUTE_ARTICLES_LANG}/de`} key={`${ROUTE_ARTICLES_LANG}/de`}>
                <ListItem sx={styles.listItem}>
                    <ListItemIcon
                        sx={{
                            justifyContent: 'center',
                            paddingTop: theme.spacing(3),
                            cursor: 'pointer',
                        }}
                    >
                        <ArticlesIcon color={getIconColor(location.pathname, ROUTE_ARTICLES)} />
                    </ListItemIcon>
                </ListItem>
            </NavLink>

            <NavLink to={ROUTE_AUDIT} key={ROUTE_AUDIT}>
                <ListItem sx={{...styles.listItem}}>
                    <ListItemIcon
                        sx={{
                            justifyContent: 'center',
                            paddingTop: theme.spacing(3),
                            cursor: 'pointer',
                        }}
                    >
                        <AuditIcon color={getIconColor(location.pathname, ROUTE_AUDIT)} />
                    </ListItemIcon>
                </ListItem>
            </NavLink>

            <NavLink to={ROUTE_DASHBOARD} key={ROUTE_DASHBOARD}>
                <ListItem sx={styles.listItem}>
                    <ListItemIcon
                        sx={{
                            justifyContent: 'center',
                            paddingTop: theme.spacing(3),
                            cursor: 'pointer',
                        }}
                    >
                        <DashboardIcon color={getIconColor(location.pathname, ROUTE_DASHBOARD)} />
                    </ListItemIcon>
                </ListItem>
            </NavLink>

            <NavLink to={ROUTE_IMAGES} key={ROUTE_IMAGES}>
                <ListItem sx={styles.listItem}>
                    <ListItemIcon
                        sx={{
                            justifyContent: 'center',
                            paddingTop: theme.spacing(3),
                            cursor: 'pointer',
                        }}
                    >
                        <ImagesIcon color={getIconColor(location.pathname, ROUTE_IMAGES)} />
                    </ListItemIcon>
                </ListItem>
            </NavLink>

            <NavLink to={`${ROUTE_MEDIA_FILES_LANG}/de`} key={`${ROUTE_MEDIA_FILES_LANG}/de`}>
                <ListItem sx={styles.listItem}>
                    <ListItemIcon
                        sx={{
                            justifyContent: 'center',
                            paddingTop: theme.spacing(3),
                            cursor: 'pointer',
                        }}
                    >
                        <MediaFilesIcon color={getIconColor(location.pathname, ROUTE_MEDIA_FILES_LANG)} />
                    </ListItemIcon>
                </ListItem>
            </NavLink>

            <NavLink to={`${ROUTE_FILES_LANG}/de`} key={`${ROUTE_FILES_LANG}/de`}>
                <ListItem sx={styles.listItem}>
                    <ListItemIcon
                        sx={{
                            justifyContent: 'center',
                            paddingTop: theme.spacing(3),
                            cursor: 'pointer',
                        }}
                    >
                        <FilesIcon color={getIconColor(location.pathname, ROUTE_FILES_LANG)} />
                    </ListItemIcon>
                </ListItem>
            </NavLink>

            {showAdminOptions && (
                <NavLink to={`${ROUTE_CHANNELS}/de`} key={`${ROUTE_CHANNELS}/de`}>
                    <ListItem sx={styles.listItem}>
                        <ListItemIcon
                            sx={{
                                justifyContent: 'center',
                                paddingTop: theme.spacing(3),
                                cursor: 'pointer',
                            }}
                        >
                            <MagazinesIcon color={getIconColor(location.pathname, ROUTE_CHANNELS)} />
                        </ListItemIcon>
                    </ListItem>
                </NavLink>
            )}

            <NavLink to={`${ROUTE_TEAMS}/de`} key={`${ROUTE_TEAMS}/de`}>
                <ListItem sx={styles.listItem}>
                    <ListItemIcon
                        sx={{
                            justifyContent: 'center',
                            paddingTop: theme.spacing(3),
                            cursor: 'pointer',
                        }}
                    >
                        <TeamsIcon color={getIconColor(location.pathname, ROUTE_TEAMS)} />
                    </ListItemIcon>
                </ListItem>
            </NavLink>

            <NavLink to={ROUTE_NEWSLETTER} key={ROUTE_NEWSLETTER}>
                <ListItem sx={styles.listItem}>
                    <ListItemIcon
                        sx={{
                            justifyContent: 'center',
                            paddingTop: theme.spacing(3),
                            cursor: 'pointer',
                        }}
                    >
                        <NewslettersIcon color={getIconColor(location.pathname, ROUTE_NEWSLETTER)} />
                    </ListItemIcon>
                </ListItem>
            </NavLink>

            {showAdminOptions && (
                <NavLink to={ROUTE_SEARCH_USERS} key={ROUTE_SEARCH_USERS}>
                    <ListItem sx={styles.listItem}>
                        <ListItemIcon
                            sx={{
                                justifyContent: 'center',
                                paddingTop: theme.spacing(3),
                                cursor: 'pointer',
                            }}
                        >
                            <UsersIcon color={getIconColor(location.pathname, ROUTE_SEARCH_USERS)} />
                        </ListItemIcon>
                    </ListItem>
                </NavLink>
            )}

            {(showAdminOptions ||
                user.userId === 'VXNlcjoxOTE=' ||
                user.userId === 'VXNlcjozMjc3' ||
                user.userId === 'VXNlcjoxNzY3NQ==') && (
                <NavLink to={`${ROUTE_TAGS}/de`} key={`${ROUTE_TAGS}/de`}>
                    <ListItem sx={styles.listItem}>
                        <ListItemIcon
                            sx={{
                                justifyContent: 'center',
                                paddingTop: theme.spacing(3),
                                cursor: 'pointer',
                            }}
                        >
                            <TagsIcon color={getIconColor(location.pathname, ROUTE_TAGS)} />
                        </ListItemIcon>
                    </ListItem>
                </NavLink>
            )}

            {showAdminOptions && (
                <NavLink to={`${ROUTE_PAYMENTS_LANG}/de`} key={`${ROUTE_PAYMENTS_LANG}/de`}>
                    <ListItem sx={styles.listItem}>
                        <ListItemIcon
                            sx={{
                                justifyContent: 'center',
                                paddingTop: theme.spacing(3),
                                cursor: 'pointer',
                            }}
                        >
                            <CashRegisterIcon color={getIconColor(location.pathname, ROUTE_PAYMENTS)} />
                        </ListItemIcon>
                    </ListItem>
                </NavLink>
            )}

            <NavLink to={`${ROUTE_POINTS_LANG}/de`} key={`${ROUTE_POINTS_LANG}/de`}>
                <ListItem sx={styles.listItem}>
                    <ListItemIcon
                        sx={{
                            justifyContent: 'center',
                            paddingTop: theme.spacing(3),
                            cursor: 'pointer',
                        }}
                    >
                        <PointsIcon color={getIconColor(location.pathname, ROUTE_POINTS_LANG)} />
                    </ListItemIcon>
                </ListItem>
            </NavLink>

            {showAdminOptions && (
                <NavLink to={`${ROUTE_SOCIAL_LINKS}`} key={`${ROUTE_SOCIAL_LINKS}`}>
                    <ListItem sx={styles.listItem}>
                        <ListItemIcon
                            sx={{
                                justifyContent: 'center',
                                paddingTop: theme.spacing(3),
                                cursor: 'pointer',
                            }}
                        >
                            <SocialLinksIcon color={getIconColor(location.pathname, ROUTE_SOCIAL_LINKS)} />
                        </ListItemIcon>
                    </ListItem>
                </NavLink>
            )}

            {showAdminOptions && (
                <NavLink to={`${ROUTE_EVENTS_LANG}/de`} key={`${ROUTE_EVENTS_LANG}/de`}>
                    <ListItem sx={styles.listItem}>
                        <ListItemIcon
                            sx={{
                                justifyContent: 'center',
                                paddingTop: theme.spacing(3),
                                cursor: 'pointer',
                            }}
                        >
                            <EventsIcon color={getIconColor(location.pathname, ROUTE_EVENTS_LANG)} />
                        </ListItemIcon>
                    </ListItem>
                </NavLink>
            )}

            <NavLink to={ROUTE_TUTORIALS} key={ROUTE_TUTORIALS}>
                <ListItem sx={{...styles.listItem, mb: 38}}>
                    <ListItemIcon
                        sx={{
                            justifyContent: 'center',
                            paddingTop: theme.spacing(3),
                            cursor: 'pointer',
                        }}
                    >
                        <TutorialsIcon color={getIconColor(location.pathname, ROUTE_TUTORIALS)} />
                    </ListItemIcon>
                </ListItem>
            </NavLink>

            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    background: theme.palette.background.default,
                    borderTop: '1px solid',
                    borderTopColor: colors.gray80,
                    pb: 2,
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        top: '-41px',
                        left: 0,
                        display: 'block',
                        width: '100%',
                        height: '40px',
                        backgroundImage: (theme.palette as any).gradients.navigation,
                        pointerEvents: 'none',
                    },
                }}
            >
                <ListItem sx={styles.listItem} onClick={handleThemeSwitch}>
                    <ListItemIcon
                        sx={{
                            justifyContent: 'center',
                            paddingTop: theme.spacing(3),
                            cursor: 'pointer',
                        }}
                    >
                        {riffThemeMode === 'light' ? (
                            <Brightness4Icon color="disabled" />
                        ) : (
                            <Brightness7Icon color="disabled" />
                        )}
                    </ListItemIcon>
                </ListItem>
                <ListItem sx={styles.listItem} onClick={handleSentryFeedback}>
                    <ListItemIcon
                        sx={{
                            justifyContent: 'center',
                            paddingTop: theme.spacing(3),
                            cursor: 'pointer',
                        }}
                    >
                        <BugReport color="disabled" />
                    </ListItemIcon>
                </ListItem>
                {isMasqueraded ? (
                    <ListItem sx={styles.listItem} onClick={useUnmasquerade}>
                        <ListItemIcon
                            sx={{
                                justifyContent: 'center',
                                paddingTop: theme.spacing(3),
                                cursor: 'pointer',
                            }}
                        >
                            <UnmaskIcon color={'primary'} />
                        </ListItemIcon>
                    </ListItem>
                ) : (
                    <ListItem sx={styles.listItem} key="logout" onClick={handleLogout}>
                        <ListItemIcon
                            sx={{
                                justifyContent: 'center',
                                pt: 3,
                                cursor: isLoading ? '' : 'pointer',
                            }}
                        >
                            {isLoading ? <Preloader /> : <LogoutIcon color={'primary'} />}
                        </ListItemIcon>
                    </ListItem>
                )}
            </Box>
        </List>
    );
});

const AppNavigation: React.FC = memo(() => {
    const theme = useTheme();
    const hidden = useMediaQuery(theme.breakpoints.down('xs'));

    return !hidden ? (
        <Drawer
            component={'nav'}
            sx={{
                width: 56,
                [theme.breakpoints.up('md')]: {
                    width: 86,
                },
            }}
            variant="permanent"
            open
        >
            <DrawerContent />
        </Drawer>
    ) : null;
});

export default AppNavigation;
