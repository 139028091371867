export const ROUTE_ARTICLES = '/articles';
export const ROUTE_ARTICLES_LANG = '/articles';
export const ROUTE_ARTICLES_EDIT = '/articles';
export const ROUTE_PAGES = '/pages';
export const ROUTE_PAGES_LANG = '/pages';
export const ROUTE_PAGES_EDIT = '/pages';
export const ROUTE_PAYMENTS = '/payments';
export const ROUTE_PAYMENTS_LANG = '/payments';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_IMAGES = '/images';
export const ROUTE_MEDIA_FILES_LANG = '/mediaFiles';
export const ROUTE_FILES_LANG = '/files';
export const ROUTE_POINTS_LANG = '/points';
export const ROUTE_CHANNELS = '/magazines';
export const ROUTE_CHANNELS_EDIT = '/magazines';
export const ROUTE_TEAMS = '/teams';
export const ROUTE_NEWSLETTER = '/newsletter';
export const ROUTE_USER = '/user';
export const ROUTE_TAGS = '/tags';
export const ROUTE_TUTORIALS = '/tutorials';
export const ROUTE_AUDIT = '/audit';
export const ROUTE_SEARCH_USERS = '/searchUsers';
export const ROUTE_SOCIAL_LINKS = '/socialLinks';
export const ROUTE_EVENTS_LANG = '/events';
export const ROUTE_EVENTS_EDIT = '/events';
export const ROUTE_LOGIN = '/login';
export const ROUTE_LOGOUT = '/logout';
export const ROUTE_HOME = '/';

export interface ParamTypes {
    [key: string]: string;
    locale: string;
    userId: string;
    articleId: string;
    pageId: string;
    channelId: string;
    teamId: string;
    eventId: string;
}
