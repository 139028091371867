import React from 'react';
import {Breakpoint, Dialog, DialogTitle, IconButton, useTheme} from '@mui/material';
import {CloseIcon} from '../../assets/UiIcons';
import {useTranslation} from 'react-i18next';

export const StyledDialog: React.FC<{
    isOpen: boolean;
    handleClose(): void;
    maxWidth?: Breakpoint | false;
    disableEscapeKeyDown?: boolean;
    title: string;
    children?: React.ReactNode;
}> = (props) => {
    const {isOpen, handleClose, maxWidth, disableEscapeKeyDown = true, title, children} = props;
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <Dialog
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick') return;
                handleClose();
            }}
            open={isOpen}
            fullWidth={true}
            maxWidth={maxWidth ?? 'sm'}
            disableEscapeKeyDown={disableEscapeKeyDown}
            scroll={'paper'}
        >
            <DialogTitle>
                {title}
                <IconButton aria-label={t('action.close')} onClick={handleClose}>
                    <CloseIcon
                        sx={{
                            fontSize: 14,
                            [theme.breakpoints.down('sm')]: {
                                fontSize: 12,
                            },
                        }}
                    />
                </IconButton>
            </DialogTitle>
            {children}
        </Dialog>
    );
};
