import React, {createContext, useContext, useEffect, useState} from 'react';
import AppNavigation from './navigation/AppNavigation';
import {Box, CssBaseline} from '@mui/material';
import {PageProps} from '../../types/page';

// Create a context for the height mode and setter function
const HeightModeContext = createContext<{
    isFullHeight: boolean;
    setIsFullHeight: (mode: boolean) => void;
}>({
    isFullHeight: false,
    setIsFullHeight: () => {},
});

// Custom hook to use the height mode context
export const useHeightMode = () => useContext(HeightModeContext);

interface AppLayoutProps {
    children?: React.ReactNode;
    hideNavigation?: boolean;
}

export const AppLayout: React.FC<AppLayoutProps> = ({children, hideNavigation = false}) => {
    const [isFullHeight, setIsFullHeight] = useState(false);

    return (
        <HeightModeContext.Provider value={{isFullHeight, setIsFullHeight}}>
            <Box
                sx={{
                    display: 'flex',
                    minHeight: '100vh',
                    maxWidth: '100vw',
                    overflow: 'hidden',
                }}
            >
                <CssBaseline />

                {!hideNavigation && <AppNavigation />}

                {/* page content */}
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: (theme) => `${theme.mixins.toolbar.minHeight}px`,
                        paddingRight: 3,
                        paddingLeft: 3,
                        paddingBottom: 0,
                        width: '100%',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        component={'main'}
                        sx={{
                            flexGrow: 1,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            ...(isFullHeight && {
                                height: (theme) =>
                                    `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(3)})`,
                                overflow: 'hidden',
                            }),
                            overflowX: 'auto',
                        }}
                    >
                        {children}
                    </Box>
                </Box>

                {/* modal portal root */}
                <Box id={'modal-root'} className={'modal-root'}></Box>
            </Box>
        </HeightModeContext.Provider>
    );
};

// Higher-order component to handle page height mode
export const withPageLayout = <P extends object>(WrappedComponent: React.ComponentType<P>, pageProps: PageProps) => {
    return function WithPageLayoutComponent(props: P) {
        const {setIsFullHeight} = useHeightMode();

        useEffect(() => {
            setIsFullHeight(pageProps.isFullHeight ?? false);

            return () => {
                setIsFullHeight(false);
            };
        }, [setIsFullHeight]);

        return <WrappedComponent {...props} />;
    };
};
