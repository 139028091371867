import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ThemeState} from '../../../types/redux';

const initialState: ThemeState = {
    mode: 'light',
    isUserPreference: false,
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme: (state, action: PayloadAction<ThemeState>) => {
            return {...action.payload};
        },
    },
});

// Export the action creators
export const {setTheme} = themeSlice.actions;

// Export the reducer
export default themeSlice.reducer;
