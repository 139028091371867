import React from 'react';
import {RouteObject} from 'react-router';
import {LoginPage} from './pages/LoginPage';
import {LogoutPage} from './pages/LogoutPage';
import {HomePage} from './pages/HomePage';
import {
    ROUTE_ARTICLES,
    ROUTE_ARTICLES_EDIT,
    ROUTE_ARTICLES_LANG,
    ROUTE_AUDIT,
    ROUTE_CHANNELS,
    ROUTE_CHANNELS_EDIT,
    ROUTE_DASHBOARD,
    ROUTE_EVENTS_EDIT,
    ROUTE_EVENTS_LANG,
    ROUTE_FILES_LANG,
    ROUTE_HOME,
    ROUTE_IMAGES,
    ROUTE_LOGIN,
    ROUTE_LOGOUT,
    ROUTE_MEDIA_FILES_LANG,
    ROUTE_NEWSLETTER,
    ROUTE_PAGES,
    ROUTE_PAGES_EDIT,
    ROUTE_PAGES_LANG,
    ROUTE_PAYMENTS_LANG,
    ROUTE_POINTS_LANG,
    ROUTE_SEARCH_USERS,
    ROUTE_SOCIAL_LINKS,
    ROUTE_TAGS,
    ROUTE_TEAMS,
    ROUTE_TUTORIALS,
    ROUTE_USER,
} from './lib/utils/router';
import {withPageLayout} from './components/structure/AppLayout';

const wrapWithFullHeight = <T extends React.ComponentType<any>>(Component: T) =>
    withPageLayout(Component, {isFullHeight: true});

export const routes: RouteObject[] = [
    {
        path: ROUTE_LOGIN,
        element: <LoginPage />,
    },
    {
        path: ROUTE_LOGOUT,
        element: <LogoutPage />,
    },
    {
        path: ROUTE_HOME,
        element: <HomePage />,
    },
    {
        path: `${ROUTE_USER}/:locale/:userId`,
        lazy: async () => {
            const {UserPage} = await import('./pages/UserPage');
            return {
                Component: UserPage,
            };
        },
    },
    {
        path: ROUTE_ARTICLES,
        lazy: async () => {
            const {ArticlesPage} = await import('./pages/ArticlesPage');
            return {Component: ArticlesPage};
        },
    },
    {
        path: `${ROUTE_ARTICLES_LANG}/:locale`,
        lazy: async () => {
            const {ArticlesList} = await import('./pages/Articles/ArticlesList');
            return {
                Component: wrapWithFullHeight(ArticlesList),
            };
        },
    },
    {
        path: `${ROUTE_ARTICLES_EDIT}/:locale/:articleId`,
        lazy: async () => {
            const {EditArticlePage} = await import('./pages/Articles/EditArticlePage');
            return {Component: EditArticlePage};
        },
    },
    {
        path: ROUTE_PAGES,
        lazy: async () => {
            const {PagesPage} = await import('./pages/PagesPage');
            return {Component: PagesPage};
        },
    },
    {
        path: `${ROUTE_PAGES_LANG}/:locale`,
        lazy: async () => {
            const {PagesList} = await import('./pages/Pages/PagesList');
            return {
                Component: wrapWithFullHeight(PagesList),
            };
        },
    },
    {
        path: `${ROUTE_PAGES_EDIT}/:locale/:pageId`,
        lazy: async () => {
            const {EditPagePage} = await import('./pages/Pages/EditPagePage');
            return {Component: EditPagePage};
        },
    },
    {
        path: `${ROUTE_PAYMENTS_LANG}/:locale`,
        lazy: async () => {
            const {PaymentsList} = await import('./pages/Payments/PaymentsList');
            return {
                Component: wrapWithFullHeight(PaymentsList),
            };
        },
    },
    {
        path: ROUTE_DASHBOARD,
        lazy: async () => {
            const {DashboardPage} = await import('./pages/DashboardPage');
            return {Component: DashboardPage};
        },
    },
    {
        path: `${ROUTE_CHANNELS}/:locale`,
        lazy: async () => {
            const {ChannelsList} = await import('./pages/Channels/ChannelsList');
            return {
                Component: wrapWithFullHeight(ChannelsList),
            };
        },
    },
    {
        path: `${ROUTE_CHANNELS_EDIT}/:locale/:channelId`,
        lazy: async () => {
            const {EditChannelPage} = await import('./pages/Channels/EditChannelPage');
            return {Component: EditChannelPage};
        },
    },
    {
        path: `${ROUTE_TEAMS}/:locale`,
        lazy: async () => {
            const {TeamsList} = await import('./pages/Teams/TeamsList');
            return {
                Component: wrapWithFullHeight(TeamsList),
            };
        },
    },
    {
        path: `${ROUTE_TEAMS}/:locale/:teamId`,
        lazy: async () => {
            const {EditTeamPage} = await import('./pages/Teams/EditTeamPage');
            return {Component: EditTeamPage};
        },
    },
    {
        path: ROUTE_NEWSLETTER,
        lazy: async () => {
            const {NewsletterPage} = await import('./pages/NewsletterPage');
            return {Component: NewsletterPage};
        },
    },
    {
        path: ROUTE_IMAGES,
        lazy: async () => {
            const {ImagesPage} = await import('./pages/ImagesPage');
            return {Component: ImagesPage};
        },
    },
    {
        path: `${ROUTE_MEDIA_FILES_LANG}/:locale`,
        lazy: async () => {
            const {MediaFilesList} = await import('./pages/MediaFiles/MediaFilesList');
            return {
                Component: wrapWithFullHeight(MediaFilesList),
            };
        },
    },
    {
        path: `${ROUTE_FILES_LANG}/:locale`,
        lazy: async () => {
            const {FilesList} = await import('./pages/Files/FilesList');
            return {
                Component: wrapWithFullHeight(FilesList),
            };
        },
    },
    {
        path: `${ROUTE_POINTS_LANG}/:locale`,
        lazy: async () => {
            const {PointsPage} = await import('./pages/Points/PointsPage');
            return {Component: PointsPage};
        },
    },
    {
        path: ROUTE_TUTORIALS,
        lazy: async () => {
            const {TutorialsPage} = await import('./pages/TutorialsPage');
            return {Component: TutorialsPage};
        },
    },
    {
        path: ROUTE_AUDIT,
        lazy: async () => {
            const {AuditList} = await import('./pages/Audit/AuditList');
            return {
                Component: wrapWithFullHeight(AuditList),
            };
        },
    },
    {
        path: ROUTE_SEARCH_USERS,
        lazy: async () => {
            const {SearchUsersPage} = await import('./pages/SearchUsers/SearchUsersPage');
            return {
                Component: wrapWithFullHeight(SearchUsersPage),
            };
        },
    },
    {
        path: `${ROUTE_TAGS}/:locale`,
        lazy: async () => {
            const {TagsList} = await import('./pages/Tags/TagsList');
            return {
                Component: wrapWithFullHeight(TagsList),
            };
        },
    },
    {
        path: ROUTE_SOCIAL_LINKS,
        lazy: async () => {
            const {SocialLinksPage} = await import('./pages/SocialLinksPage');
            return {Component: SocialLinksPage};
        },
    },
    {
        path: `${ROUTE_EVENTS_LANG}/:locale`,
        lazy: async () => {
            const {EventsList} = await import('./pages/Events/EventsList');
            return {Component: EventsList};
        },
    },
    {
        path: `${ROUTE_EVENTS_EDIT}/:locale/:eventId`,
        lazy: async () => {
            const {EditEventPage} = await import('./pages/Events/EditEventPage');
            return {Component: EditEventPage};
        },
    },
];
