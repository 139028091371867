import React, {memo, useState} from 'react';
import {Box, Button, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {LogoRIcon} from '../assets/UiIcons';
import {Preloader} from '../components/structure/Preloader';
import {Controller, useForm} from 'react-hook-form';
import {ROUTE_ARTICLES_LANG, ROUTE_HOME} from '../lib/utils/router';
import {useAuth} from '../lib/hooks/useAuth';
import {err, log} from '../lib/utils/logger';
import {useNavigate} from 'react-router';
import {useSelector} from 'react-redux';
import {AppState, UserState, UserStatus} from '../types/redux';

interface LoginValues {
    username: string;
    password: string;
}

export const LoginPage: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const MemoizedLogoRIcon = memo(LogoRIcon);

    const user: UserState = useSelector<AppState, UserState>((state) => state.user);

    const [isLoading, setIsLoading] = useState(false);

    // const [authToken, setAuthToken] = useState(loadLocalData(LOCAL_KEY_TOKEN));
    const {login} = useAuth((token) => {
        // Token updates are handled by useAuth internally
        if (token) {
            log('received new auth token');
        }
    });

    // form submit handler
    const onSubmit = (values: LoginValues) => {
        setIsLoading(true);
        return login(values.username, values.password)
            .then(() => {
                // Check if login was successful by looking at the user state
                if (user.status === UserStatus.LOGGED_IN) {
                    log('login successful');
                    // Navigate to the saved redirect location or default route
                    navigate(user.redirectLocation || {pathname: `${ROUTE_ARTICLES_LANG}/de`});
                }
            })
            .catch((error) => {
                err('login failed', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm();

    return (
        <form onSubmit={handleSubmit((data) => onSubmit(data as LoginValues))}>
            <Box
                sx={{
                    display: 'flex',
                    minHeight: 'calc(100vh)',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid container justifyContent={'center'} sx={{width: '100vw'}}>
                    <Grid size={{xs: 10, sm: 8, lg: 6}}>
                        <Box display={'flex'} alignContent={'center'} justifyContent={'center'}>
                            <NavLink to={ROUTE_HOME}>
                                <MemoizedLogoRIcon color={'primary'} />
                            </NavLink>
                        </Box>
                        <Typography variant={'h2'} my={3} textAlign={'center'}>
                            {t('login.title')}
                        </Typography>
                        <Typography variant={'h3'} textAlign={'center'}>
                            {t('login.subtitle')}
                        </Typography>
                        <Grid size={12} sx={{mt: 6}}>
                            <Controller
                                name={'username'}
                                control={control}
                                defaultValue={''}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        value={field.value ?? ''}
                                        label={t('login.username')}
                                        type={'text'}
                                        autoComplete={'username'}
                                        fullWidth={true}
                                    />
                                )}
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors.username && errors.username.type === 'required' && (
                                <span>{t('validation.shouldNotBeEmpty', {item: t('login.username')})}</span>
                            )}
                        </Grid>
                        <Grid size={12}>
                            <Controller
                                name={'password'}
                                control={control}
                                defaultValue={''}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        value={field.value ?? ''}
                                        label={t('login.password')}
                                        type={'password'}
                                        autoComplete={'current-password'}
                                        fullWidth={true}
                                        sx={{mt: 3}}
                                    />
                                )}
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors.password && errors.password.type === 'required' && (
                                <span>{t('validation.shouldNotBeEmpty', {item: t('login.password')})}</span>
                            )}
                        </Grid>
                        <Grid size={12} sx={{mt: 3}}>
                            {isLoading ? (
                                <Preloader />
                            ) : (
                                <Button type="submit" variant={'contained'} color={'primary'} fullWidth={true}>
                                    {t('login.login')}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};
