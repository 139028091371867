import {ApolloClient} from '@apollo/client';
import {AuthenticationRoleEnum, WhoAmI} from '../../types/graphqlTypes';
import {LOCAL_KEY_TOKEN, SESSION_KEY_APP_STATE} from '../constants';
import {GM_LOGIN_USER, GM_LOGIN_USER_TOKEN, GM_LOGOUT_USER, GQ_USER_WHOAMI} from './polypublisher/gql/user';
import {loadLocalData, removeLocalData, removeSessionData, saveLocalData} from '../utils/storage';

export interface AuthUser {
    userId: string;
    userName: string;
    userEmail: string;
    role: AuthenticationRoleEnum;
    showAdminOptions: boolean;
    showRootOptions: boolean;
    avatar?: string;
}

export class AuthService {
    constructor(private apolloClient: ApolloClient<any>) {}

    async login(username: string, password: string) {
        // Ensure a clean slate before login
        await this.clearAuth();

        const response = await this.apolloClient.mutate({
            mutation: GM_LOGIN_USER,
            variables: {username, password},
        });

        const token = response.data?.login;
        if (!token) {
            throw new Error('Login failed, no token received');
        }

        saveLocalData(LOCAL_KEY_TOKEN, token);

        return token;
    }

    async loginWithToken(token: string): Promise<AuthUser> {
        // Get current token for authorization
        const currentToken = this.getStoredToken();

        // Execute login mutation with current token in auth header
        const result = await this.apolloClient.mutate({
            mutation: GM_LOGIN_USER_TOKEN,
            variables: {token},
            context: {
                headers: {
                    Authorization: currentToken ?? '',
                },
            },
        });

        const newAuthToken = result.data?.loginByToken;
        if (!newAuthToken) {
            throw new Error('Login failed - no token received');
        }

        // Now we can clear previous auth and set new token
        await this.clearAuth();
        saveLocalData(LOCAL_KEY_TOKEN, newAuthToken);

        return await this.whoAmI();
    }

    async whoAmI(): Promise<AuthUser> {
        const token = this.getStoredToken();

        if (!token) {
            throw new Error('No auth token available');
        }

        const {data} = await this.apolloClient.query({
            query: GQ_USER_WHOAMI,
            fetchPolicy: 'no-cache',
        });

        const userStatus: WhoAmI = data?.whoAmI;
        if (!userStatus?.user) {
            throw new Error('No user data received');
        }

        const showAdminOptions =
            userStatus.role === AuthenticationRoleEnum.Root || userStatus.role === AuthenticationRoleEnum.Administrator;

        const showRootOptions = userStatus.role === AuthenticationRoleEnum.Root;

        return {
            userId: userStatus.user,
            userName: userStatus.fullName ?? '',
            userEmail: userStatus.email,
            role: userStatus.role ?? AuthenticationRoleEnum.Anonymous,
            showAdminOptions,
            showRootOptions,
            avatar: userStatus.avatar ?? undefined,
        };
    }

    private async clearAuth(): Promise<void> {
        // Remove all authentication-related data
        removeLocalData(LOCAL_KEY_TOKEN);
        removeSessionData(SESSION_KEY_APP_STATE);

        // Clear Apollo client cache if necessary
        try {
            await this.apolloClient.clearStore();
        } catch (error) {
            console.error('Error clearing Apollo client store:', error);
        }
    }

    async logout(): Promise<void> {
        try {
            await this.clearAuth();

            // Perform backend logout mutation
            await this.apolloClient.mutate({mutation: GM_LOGOUT_USER});
        } catch (error) {
            console.error('Logout error:', error);
            throw error;
        }
    }

    getStoredToken(): string | null {
        return loadLocalData(LOCAL_KEY_TOKEN);
    }
}
