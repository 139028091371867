import {useCallback, useMemo, useReducer, useRef} from 'react';
import {useApolloClient} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import {AuthService, AuthUser} from '../services/authService';
import {ROUTE_LOGIN, ROUTE_LOGOUT} from '../utils/router';
import {useLocation} from 'react-router';
import {loginError, loginSuccess, logout as userLogout, sessionExpired} from '../redux/slices/userSlice';
import {useAppDispatch} from '../redux/hooks';

interface AuthState {
    isLoading: boolean;
    error?: Error;
    user?: AuthUser;
}

type AuthAction =
    | {type: 'AUTH_START'}
    | {type: 'AUTH_SUCCESS'; user: AuthUser}
    | {type: 'AUTH_ERROR'; error: Error}
    | {type: 'AUTH_LOGOUT'};

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
        case 'AUTH_START':
            return {...state, isLoading: true, error: undefined};
        case 'AUTH_SUCCESS':
            return {isLoading: false, user: action.user};
        case 'AUTH_ERROR':
            return {isLoading: false, error: action.error};
        case 'AUTH_LOGOUT':
            return {isLoading: false};
        default:
            return state;
    }
};

export const useAuth = (setNewAuthToken: (token: string | null) => void) => {
    const apolloClient = useApolloClient();
    const reduxDispatch = useAppDispatch();
    const navigate = useNavigate();

    const [state, dispatch] = useReducer(authReducer, {isLoading: false});
    const isLoggingOut = useRef(false);

    const location = useLocation();

    const authService = useMemo(() => new AuthService(apolloClient), [apolloClient]);

    const handleLoginSuccess = useCallback(
        (user: AuthUser) => {
            dispatch({type: 'AUTH_SUCCESS', user});
            reduxDispatch(
                loginSuccess({
                    authToken: authService.getStoredToken(),
                    ...user,
                }),
            );
        },
        [authService, reduxDispatch],
    );

    const handleLoginError = useCallback(
        (error: Error) => {
            dispatch({type: 'AUTH_ERROR', error});
            reduxDispatch(sessionExpired());
            navigate(ROUTE_LOGOUT);
        },
        [reduxDispatch, navigate],
    );

    const login = useCallback(
        async (username: string, password: string) => {
            try {
                dispatch({type: 'AUTH_START'});

                await authService.login(username, password);

                // Get user data
                const user = await authService.whoAmI();
                handleLoginSuccess(user);
                return true;
            } catch (error) {
                // Don't call handleLoginError here as it triggers logout
                dispatch({type: 'AUTH_ERROR', error: error as Error});
                reduxDispatch(loginError((error as Error).message));
                return false;
            }
        },
        [reduxDispatch, authService, handleLoginSuccess],
    );

    const loginWithToken = useCallback(
        async (token: string) => {
            try {
                dispatch({type: 'AUTH_START'});

                await authService.loginWithToken(token);

                // Get user data
                const user = await authService.whoAmI();
                handleLoginSuccess(user);
            } catch (error) {
                handleLoginError(error as Error);
            }
        },
        [authService, handleLoginSuccess, handleLoginError],
    );

    const logout = useCallback(async () => {
        if (isLoggingOut.current) {
            return;
        }
        isLoggingOut.current = true;

        try {
            await authService.logout();
            setNewAuthToken(null);
            dispatch({type: 'AUTH_LOGOUT'});
            reduxDispatch(userLogout());
        } finally {
            isLoggingOut.current = false;
        }

        if (location.pathname !== ROUTE_LOGOUT && location.pathname !== ROUTE_LOGIN) {
            navigate(ROUTE_LOGOUT);
        }
    }, [authService, setNewAuthToken, reduxDispatch, location.pathname, navigate]);

    const checkAuth = useCallback(async () => {
        const currentToken = authService.getStoredToken();
        if (!currentToken) {
            // Call logout to ensure a clean state
            await logout();
            return;
        }

        try {
            dispatch({type: 'AUTH_START'});
            setNewAuthToken(currentToken);

            // Wait for Apollo client recreation
            await new Promise((resolve) => setTimeout(resolve, 100));

            const user = await authService.whoAmI();
            handleLoginSuccess(user);
        } catch (error) {
            handleLoginError(error as Error);
        }
    }, [authService, logout, setNewAuthToken, handleLoginSuccess, handleLoginError]);

    return {
        ...state,
        login,
        loginWithToken,
        checkAuth,
        logout,
    };
};
