import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EditImageMetaDataState} from '../../../types/redux';

const initialState: EditImageMetaDataState = {
    isOpen: false,
    image: null,
    imageIndex: null,
    paragraphType: null,
    paragraphIndex: null,
    refetchQuery: null,
};

export const editImageMetaDataSlice = createSlice({
    name: 'editImageMetaData',
    initialState,
    reducers: {
        setImageMetaData: (state, action: PayloadAction<EditImageMetaDataState>) => {
            return {...action.payload};
        },
        resetImageMetaData: () => {
            return initialState;
        },
    },
});

// Export the action creators
export const {setImageMetaData, resetImageMetaData} = editImageMetaDataSlice.actions;

// Export the reducer
export default editImageMetaDataSlice.reducer;
