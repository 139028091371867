import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UploadImageState} from '../../../types/redux';

const initialState: UploadImageState = {
    isOpen: false,
};

export const uploadImageSlice = createSlice({
    name: 'uploadImage',
    initialState,
    reducers: {
        setImageUpload: (state, action: PayloadAction<UploadImageState>) => {
            return {...action.payload};
        },
        resetImageUpload: () => {
            return initialState;
        },
    },
});

// Export the action creators
export const {setImageUpload, resetImageUpload} = uploadImageSlice.actions;

// Export the reducer
export default uploadImageSlice.reducer;
