import {onError} from '@apollo/client/link/error';
import {removeLocalData, removeSessionData} from './utils/storage';
import {LOCAL_KEY_TOKEN, SESSION_KEY_APP_STATE} from './constants';
import {SNACKBAR_AUTO_HIDE_DURATION} from './constants';
import {err} from './utils/logger';
import {GraphQLFormattedError} from 'graphql/error';
import {Store} from 'redux';
import {NetworkError} from '@apollo/client/errors';
import {setSnackbar} from './redux/slices/snackbarSlice';
import {sessionExpired} from './redux/slices/userSlice';

export const createErrorLink = (
    store: Store,
    setGraphQlErrors: (errors: readonly GraphQLFormattedError[]) => void,
    setNetworkError: (error: NetworkError | undefined) => void,
) =>
    onError(({graphQLErrors, networkError}) => {
        // Log the errors
        if (graphQLErrors && graphQLErrors.length > 0) {
            const errorObject: Record<string, any> = {...graphQLErrors[0]};
            delete errorObject['message'];
            err('Apollo error: ' + graphQLErrors[0].message, errorObject);
        }
        if (networkError) {
            err('Network error: ' + networkError.message, networkError);
        }

        // Authentication error handling
        if (
            graphQLErrors &&
            graphQLErrors.filter(
                (e) =>
                    e.message.includes('You do not have authorization') ||
                    e.message.includes('You are not allowed') ||
                    e.message.includes('Token invalid'),
            ).length > 0
        ) {
            if (store) {
                err('App logging out');
                removeLocalData(LOCAL_KEY_TOKEN);
                removeSessionData(SESSION_KEY_APP_STATE);
                store.dispatch(sessionExpired());
            } else {
                err('App cannot handle onError auth error, no dispatch provided');
            }
        }

        // Set errors for display
        setGraphQlErrors(graphQLErrors || []);
        setNetworkError(networkError);

        // Dispatch snackbar for errors
        if (store && (graphQLErrors || networkError)) {
            store.dispatch(
                setSnackbar({
                    isOpen: true,
                    severity: 'error',
                    autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION,
                    message: graphQLErrors
                        ? graphQLErrors
                              .map((graphError: GraphQLFormattedError) => {
                                  if ('debugMessage' in graphError && typeof graphError.debugMessage === 'string') {
                                      return graphError.debugMessage;
                                  }
                                  return graphError.message;
                              })
                              .join(', ')
                        : networkError
                          ? networkError.message
                          : 'An error occurred',
                }),
            );
        }
    });
