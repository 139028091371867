import * as Sentry from '@sentry/react';

const isLoggerEnabled = import.meta.env.NODE_ENV === 'development';
const isSentryEnabled = import.meta.env.VITE_SENTRY_ENABLED ?? false;

// tslint:disable-next-line:no-any
export const debug = (message?: any, ...optionalParams: any[]) => {
    if (isLoggerEnabled) {
        // tslint:disable-next-line:no-console
        console.debug(message, ...optionalParams);
    }
};

// tslint:disable-next-line:no-any
export const log = (message?: any, ...optionalParams: any[]) => {
    if (isLoggerEnabled) {
        // tslint:disable-next-line:no-console
        console.log(message, ...optionalParams);
    }
};

// tslint:disable-next-line:no-any
export const warn = (message?: any, ...optionalParams: any[]) => {
    if (isLoggerEnabled) {
        // tslint:disable-next-line:no-console
        console.warn(message, ...optionalParams);
    }
};

// tslint:disable-next-line:no-any
export const error = (message?: any, ...optionalParams: any[]) => {
    // log to console
    if (isLoggerEnabled) {
        console.error(message, ...optionalParams);
    }
    // log to Sentry
    if (isSentryEnabled) {
        // remove the trace
        // if an error object is passed, use it
        const errorObject = optionalParams.find((param) => param instanceof Error);

        Sentry.withScope((scope) => {
            // set each optional parameter as context
            optionalParams.forEach((param, index) => {
                if (!(param instanceof Error)) {
                    scope.setContext(`param_${index}`, {data: param});
                }
            });
            if (errorObject) {
                Sentry.captureException(errorObject);
            } else {
                // if no error object is passed, use the message
                Sentry.captureException(new Error(message));
            }
        });
    }
};

export const err = error;
