const loadStorageData = (storage: Storage, key: string) => {
    try {
        const serializedState = storage.getItem(key);
        if (!serializedState) {
            return undefined;
        }

        return JSON.parse(serializedState);
    } catch (err) {
        console.error(err);
        return undefined;
    }
};

const saveStorageData = (storage: Storage, key: string, value: any) => {
    try {
        const serializedState = JSON.stringify(value);
        storage.setItem(key, serializedState);
    } catch (err) {
        console.error(err);
    }
};

const removeStorageData = (storage: Storage, key: string) => {
    try {
        storage.removeItem(key);
    } catch (err) {
        console.error(err);
    }
};

export const loadSessionData = (key: string) => {
    return loadStorageData(sessionStorage, key);
};

export const saveSessionData = (key: string, value: any) => {
    return saveStorageData(sessionStorage, key, value);
};

export const removeSessionData = (key: string) => {
    return removeStorageData(sessionStorage, key);
};

export const loadLocalData = (key: string) => {
    return loadStorageData(localStorage, key);
};

export const saveLocalData = (key: string, value: any) => {
    return saveStorageData(localStorage, key, value);
};

export const removeLocalData = (key: string) => {
    return removeStorageData(localStorage, key);
};
