import {alpha, PaletteMode, createTheme, Theme} from '@mui/material';

declare module '@mui/material/styles' {
    type DefaultTheme = Theme;
}

const defaultTheme = createTheme();

export const colors = {
    white: '#ffffff',
    whiteAlpha: 'rgba(225,225,225,.85)',
    black: '#000000',
    blackAlpha: 'rgba(0,0,0,.75)',
    gray1: '#080808',
    gray5: '#121212',
    gray10: '#2C2C2C',
    gray20: '#333333',
    gray40: '#707070',
    gray50: '#767676',
    gray70: '#9A9A9A',
    gray80: '#CCCCCC',
    gray95: '#F4F5F7',
    red50: '#B81C29',
    red70: '#E62434',
    red80: '#F99398',
    greenGray40: '#505863',
    greenGray60: '#939698',
    greenGray80: '#C3C6C8',
    turquoise50: '#427F86',
    turquoise30: '#E7F1F3',
    green30: '#2f6b09',
    green50: '#91F255',
    calcHeader: '#fefffe',
    scienceBlue: '#96C8DC',
    internationalPurple: '#DBB4F1',
    environmentGreen: '#70E4A2',
    societyPurple: '#A791DB',
    technologyYellow: '#FAB46E',
    linkEditorLight: '#1b75d0',
    linkEditorDark: '#4a85cc',
    linkOkEditorLight: '#32CD32',
    linkOkEditorDark: '#228B22',
    linkWarningEditorLight: '#FFA500',
    linkWarningEditorDark: '#FF8C00',
    linkErrorEditorLight: '#FF4500',
    linkErrorEditorDark: '#DC143C',
    gradients: {
        turquoiseLinear01: 'linear-gradient(0deg, rgba(72,138,145,1) 0%, rgba(126,190,196,1) 100%)',
    },
};

// export const articleSectionSpacing = 6;
export const FONT_BODY = 'Inter, sans-serif'; // 'Raleway, Arial, sans-serif'
export const FONT_HEADING = 'Inter, sans-serif'; // 'Roboto Slab, Arial, sans-serif'
export const FONT_MONO = 'InterMono, monospace, sans-serif';
export const SCRAMBLE_FONT_BODY = 'redacted_scriptlight, Arial, sans-serif';
// export const SCRAMBLE_FONT_HEADING = 'redactedregular, Arial, sans-serif';

export const getThemeOptions = (mode: PaletteMode) => ({
    typography: {
        fontFamily: FONT_BODY,
        // element/slab/larger - hero title
        h1: {
            fontFamily: FONT_HEADING,
            fontWeight: 'bold',
            fontSize: 32,
            lineHeight: 1.3,
            wordWrap: 'break-word',
            hyphens: 'auto',
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: 48,
            },
        },
        // element/slab/large, copy/H1
        h2: {
            fontFamily: FONT_HEADING,
            fontWeight: 'bold',
            fontSize: 24,
            lineHeight: 1.5,
            wordWrap: 'break-word',
            hyphens: 'auto',
            // letterSpacing: 2,
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: 32,
            },
        },
        // element/slab/normal
        h3: {
            fontFamily: FONT_HEADING,
            fontWeight: 'bold',
            fontSize: 18,
            lineHeight: 1.5,
            wordWrap: 'break-word',
            hyphens: 'auto',
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: 24,
            },
        },
        // element/slab/small
        h4: {
            fontFamily: FONT_HEADING,
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: 1.5,
            wordWrap: 'break-word',
            hyphens: 'auto',
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: 18,
            },
        },
        // element/sans/normal/bold
        h5: {
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: 1.5,
            wordWrap: 'break-word',
            hyphens: 'auto',
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: 18,
            },
        },
        h6: {
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: 1.57,
        },
        // element/sans/normal  - default copy text
        body1: {
            fontSize: 16,
            lineHeight: 1.5,
            wordWrap: 'break-word',
            hyphens: 'auto',
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: 18,
            },
        },
        // element/sans/small
        body2: {
            fontSize: 14,
            lineHeight: 1.5,
            wordWrap: 'break-word',
            hyphens: 'auto',
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: 16,
            },
        },
        // element/sans/smaller
        caption: {
            fontSize: 11,
            letterSpacing: '.05rem',
            lineHeight: 1.5,
            wordWrap: 'break-word',
            hyphens: 'auto',
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: 13,
            },
        },
        // button/label, same as element/sans/smaller
        button: {
            letterSpacing: 1.5,
            textTransform: 'uppercase',
            fontSize: 11,
            lineHeight: 1.5,
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: 12,
            },
        },
        // element/sans/default 2.0
        subtitle1: {
            fontSize: 16,
            lineHeight: 2,
            wordWrap: 'break-word',
            hyphens: 'auto',
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: 18,
            },
        },
        subtitle2: {},
        overline: {
            fontSize: 8,
            letterSpacing: '.05rem',
            lineHeight: 1.5,
            wordWrap: 'break-word',
            hyphens: 'auto',
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: 10,
            },
        },
    },
    // i.e. button border radius
    shape: {
        borderRadius: 0,
    },
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                  background: {
                      // set body background color
                      default: colors.gray95,
                      second: colors.turquoise30,
                      offset: colors.white,
                      shadow: colors.gray40,
                      paper: colors.gray95,
                  },
                  paper: {
                      background: colors.gray95,
                  },
                  text: {
                      primary: colors.greenGray40,
                      secondary: colors.gray50,
                  },
                  primary: {
                      main: colors.turquoise50,
                      light: colors.calcHeader,
                  },
                  secondary: {
                      main: colors.greenGray40,
                      light: colors.gray95,
                  },
                  error: {
                      main: colors.red70,
                      light: colors.red80,
                  },
                  gradients: {
                      checkerboard:
                          'linear-gradient(45deg, #ddd 25%, transparent 25%),' +
                          'linear-gradient(-45deg, #ddd 25%, transparent 25%),' +
                          'linear-gradient(45deg, transparent 75%, #ddd 75%),' +
                          'linear-gradient(-45deg, transparent 75%, #ddd 75%)',
                      navigation: 'linear-gradient(to top, rgba(244,245,247,1), rgba(244,245,247,0))',
                  },
              }
            : {
                  background: {
                      default: colors.gray1,
                      second: colors.gray10,
                      offset: colors.gray20,
                      shadow: colors.black,
                      paper: colors.gray1,
                  },
                  paper: {
                      background: colors.gray1,
                  },
                  primary: {
                      main: colors.turquoise50,
                  },
                  gradients: {
                      checkerboard:
                          'linear-gradient(45deg, #222 25%, transparent 25%),' +
                          'linear-gradient(-45deg, #222 25%, transparent 25%),' +
                          'linear-gradient(45deg, transparent 75%, #222 75%),' +
                          'linear-gradient(-45deg, transparent 75%, #222 75%)',
                      navigation: 'linear-gradient(to top, rgba(18,18,18,1), rgba(18,18,18,0))',
                  },
              }),
    },
    // set base spacing to 5
    spacing: 5,
    // zIndex
    zIndex: {
        mobileStepper: 400,
        speedDial: 450,
        appBar: 500,
        drawer: 600,
        modal: 700,
        snackbar: 800,
        tooltip: 900,
    },
    components: {
        // structural
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backdropFilter: 'blur(5px)',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: mode === 'light' ? 'paper' : colors.black,
                    backgroundImage: mode === 'light' ? 'inherit' : 'unset',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: mode === 'light' ? 'inherit' : alpha(colors.turquoise50, 0.25),
                },
            },
        },
        // cards
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid',
                    borderColor: mode === 'light' ? 'inherit' : alpha(colors.turquoise50, 0.25),
                },
                title: {
                    fontSize: 12,
                    letterSpacing: '.05rem',
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 13,
                    },
                },
                subheader: {
                    fontSize: 12,
                    letterSpacing: '.05rem',
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 13,
                    },
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    a: {
                        color: 'inherit',
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                        '&:visited': {
                            color: 'inherit',
                        },
                    },
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderTop: '1px solid',
                    borderColor: mode === 'light' ? 'inherit' : alpha(colors.turquoise50, 0.25),
                },
            },
        },
        // // collapse
        // MuiCollapse: {
        //     styleOverrides: {
        //         root: {
        //             borderStyle: 'solid',
        //             borderWidth: '1px',
        //             borderColor: alpha(colors.turquoise50, 0.5),
        //             backgroundColor: alpha(colors.turquoise50, 0.04),
        //             padding: defaultTheme.spacing(3),
        //         },
        //     },
        // },
        // dialog
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 15,
                    backgroundColor: mode === 'light' ? 'paper' : colors.black,
                    backgroundImage: mode === 'light' ? 'inherit' : 'none',
                    border: mode === 'light' ? 'none' : '1px solid',
                    borderColor: mode === 'light' ? 'inherit' : alpha(colors.turquoise50, 0.25),
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: defaultTheme.spacing(2),
                    fontWeight: 'bold',
                    backgroundColor: mode === 'light' ? colors.white : colors.black,
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: defaultTheme.spacing(2),
                    margin: 0,
                    backgroundColor: mode === 'light' ? colors.white : colors.black,
                },
            },
        },
        // typography
        MuiTypography: {
            styleOverrides: {
                overline: {
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 13,
                    },
                },
            },
        },
        // links
        MuiLink: {
            defaultProps: {
                underline: 'none',
            },
        },
        // buttons
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    boxShadow: 'none',
                },
            },
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    '&.MuiButtonGroup-horizontal': {
                        '& .MuiButton-root': {
                            '&:first-of-type': {
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                            },
                            '&:last-of-type': {
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                            },
                        },
                    },
                    '&.MuiButtonGroup-vertical': {
                        '& .MuiButton-root': {
                            '&:first-of-type': {
                                borderTopLeftRadius: 5,
                                borderTopRightRadius: 5,
                            },
                            '&:last-of-type': {
                                borderBottomLeftRadius: 5,
                                borderBottomRightRadius: 5,
                            },
                        },
                    },
                },
            },
        },
        // toggle button
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    '&.MuiToggleButtonGroup-root': {
                        borderRadius: 0,
                    },
                },
                grouped: {
                    '&.MuiToggleButton-root': {
                        border: '1px solid',
                        borderColor: mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : alpha(colors.turquoise50, 0.5),
                        '&:not(:first-of-type)': {
                            borderLeft: '1px solid',
                            borderLeftColor: mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : alpha(colors.turquoise50, 0.5),
                            marginLeft: '-1px',
                        },
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    border: '1px solid',
                    borderColor: mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : alpha(colors.turquoise50, 0.5),
                    color: mode === 'light' ? 'inherit' : colors.turquoise50,
                    fontSize: 12,
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 13,
                    },
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor:
                            mode === 'light' ? alpha(colors.gray40, 0.04) : alpha(colors.turquoise50, 0.05),
                        borderColor: mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : colors.turquoise50,
                    },
                    '&.Mui-selected': {
                        backgroundColor: mode === 'light' ? alpha(colors.gray40, 0.08) : alpha(colors.turquoise50, 0.1),
                        color: mode === 'light' ? 'inherit' : colors.turquoise50,
                        '&:hover': {
                            backgroundColor:
                                mode === 'light' ? alpha(colors.gray40, 0.12) : alpha(colors.turquoise50, 0.15),
                        },
                    },
                    '&.MuiToggleButtonGroup-groupedHorizontal': {
                        '&:first-of-type': {
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                        },
                        '&:last-of-type': {
                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5,
                        },
                    },
                    '&.MuiToggleButtonGroup-groupedVertical': {
                        '&:first-of-type': {
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,
                        },
                        '&:last-of-type': {
                            borderBottomLeftRadius: 5,
                            borderBottomRightRadius: 5,
                        },
                    },
                },
                sizeSmall: {
                    padding: '4px 10px',
                },
                sizeMedium: {
                    padding: '6px 12px',
                },
                sizeLarge: {
                    padding: '8px 14px',
                },
            },
        },
        // menu
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    letterSpacing: 1.5,
                    textTransform: 'uppercase',
                    fontSize: 11,
                    lineHeight: 1.5,
                    color: 'primary',
                    [defaultTheme.breakpoints.up('md')]: {
                        fontSize: 12,
                    },
                    '& .MuiListItemIcon-root': {
                        display: 'inherit',
                        marginRight: '8px',
                        marginLeft: '-4px',
                        maxWidth: '20px',
                        maxHeight: '20px',
                        color: 'primary',
                        fontSize: 12,
                    },
                },
            },
        },
        // forms
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    '& .MuiAutocomplete-option': {
                        fontSize: '0.875rem',
                    },
                    maxHeight: '75vh',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: 12,
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 13,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: mode === 'light' ? 'inherit' : colors.white,
                    fontSize: 12,
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 13,
                    },
                    '&.Mui-disabled .MuiInputBase-input': {
                        color: mode === 'light' ? 'inherit' : alpha(colors.turquoise50, 0.5),
                    },
                },
                input: {
                    '&&.Mui-disabled': {
                        color: mode === 'light' ? 'inherit' : alpha(colors.turquoise50, 0.5),
                    },
                    // '&::placeholder': {
                    //     opacity: 0.25,
                    //     color: 'inherit',
                    // },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: mode === 'light' ? 'inherit' : alpha(colors.white, 0.5),
                    fontSize: 12,
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 13,
                    },
                    '& .MuiTypography-root': {
                        color: mode === 'light' ? 'inherit' : alpha(colors.white, 0.5),
                        fontSize: 12,
                        [defaultTheme.breakpoints.up('sm')]: {
                            fontSize: 13,
                        },
                    },
                },
                positionStart: {
                    // Specific styles for start adornment
                },
                positionEnd: {
                    // Specific styles for end adornment
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    '&& .MuiOutlinedInput-notchedOutline': {
                        borderColor: mode === 'light' ? 'inherit' : alpha(colors.turquoise50, 0.5),
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 1,
                        borderColor: mode === 'light' ? 'inherit' : colors.turquoise50,
                    },
                    '&.Mui-focused': {
                        boxShadow: 'none',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 1,
                        borderColor: mode === 'light' ? 'inherit' : colors.turquoise50,
                    },
                    '&.Mui-focused, &:focus-within': {
                        backgroundColor: 'transparent',
                    },
                    '& .MuiOutlinedInput-notchedOutline legend': {
                        color: mode === 'light' ? 'inherit' : colors.turquoise50,
                        opacity: 1,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: mode === 'light' ? 'inherit' : colors.turquoise50,
                    fontSize: 12,
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 13,
                    },
                    '&.Mui-disabled': {
                        color: mode === 'light' ? 'inherit' : alpha(colors.turquoise50, 0.5),
                    },
                },
                outlined: {
                    color: mode === 'light' ? 'inherit' : colors.turquoise50,
                    opacity: 0.5,
                    '&.MuiInputLabel-shrink': {
                        opacity: 1,
                    },
                    '&.Mui-focused': {
                        color: mode === 'light' ? 'inherit' : colors.turquoise50,
                    },
                },
                filled: {
                    opacity: 0.5,
                    '&.MuiInputLabel-shrink': {
                        opacity: 1,
                    },
                },
                standard: {
                    opacity: 0.5,
                    '&.MuiInputLabel-shrink': {
                        opacity: 1,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: alpha(colors.turquoise50, 0.5),
                    '&:hover': {
                        color: colors.turquoise50,
                    },
                    '&.Mui-focused': {
                        color: colors.turquoise50,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: alpha(colors.turquoise50, 0.5),
                },
            },
        },
        MuiPickersToolbar: {
            styleOverrides: {
                root: {
                    color: '#bbdefb',
                    borderRadius: 6,
                    borderWidth: 1,
                    borderColor: '#2196f3',
                    border: '1px solid',
                    backgroundColor: '#0d47a1',
                },
            },
        },
        // time picker
        MuiDesktopTimePicker: {
            styleOverrides: {
                paper: {
                    backgroundColor: mode === 'light' ? 'paper' : colors.black,
                },
            },
            // defaultProps: {
            //     viewRenderers: {
            //         hours: renderTimeViewClock,
            //         minutes: renderTimeViewClock,
            //         seconds: renderTimeViewClock,
            //     },
            // },
        },
        MuiDesktopDateTimePicker: {
            styleOverrides: {
                paper: {
                    backgroundColor: mode === 'light' ? 'paper' : colors.black,
                },
            },
            // defaultProps: {
            //     viewRenderers: {
            //         hours: renderTimeViewClock,
            //         minutes: renderTimeViewClock,
            //         seconds: renderTimeViewClock,
            //     },
            // },
        },
        // chip
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    fontSize: 12,
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 13,
                    },
                    ...(mode === 'dark' && {
                        '&:not(.MuiChip-colorPrimary):not(.MuiChip-colorSecondary):not(.MuiChip-colorError):not(.MuiChip-colorInfo):not(.MuiChip-colorSuccess):not(.MuiChip-colorWarning)':
                            {
                                backgroundColor: alpha(colors.turquoise50, 0.1),
                                color: colors.turquoise50,
                                '&:hover': {
                                    backgroundColor: alpha(colors.turquoise50, 0.2),
                                },
                                '& .MuiChip-icon': {
                                    color: colors.turquoise50,
                                },
                                '&.Mui-disabled .MuiChip-icon': {
                                    color: alpha(colors.turquoise50, 0.5),
                                },
                            },
                        '&.Mui-disabled': {
                            opacity: 0.5,
                        },
                    }),
                },
                deleteIcon: {
                    fontSize: '18px',
                    ...(mode === 'dark' && {
                        '&:not(.MuiChip-colorPrimary):not(.MuiChip-colorSecondary):not(.MuiChip-colorError):not(.MuiChip-colorInfo):not(.MuiChip-colorSuccess):not(.MuiChip-colorWarning)':
                            {
                                color: alpha(colors.turquoise50, 0.7),
                                '&:hover': {
                                    color: colors.turquoise50,
                                },
                            },
                    }),
                },
                avatar: {
                    ...(mode === 'dark' && {
                        '&:not(.MuiChip-colorPrimary):not(.MuiChip-colorSecondary):not(.MuiChip-colorError):not(.MuiChip-colorInfo):not(.MuiChip-colorSuccess):not(.MuiChip-colorWarning)':
                            {
                                color: colors.turquoise50,
                                backgroundColor: alpha(colors.turquoise50, 0.2),
                            },
                    }),
                },
                icon: {
                    ...(mode === 'dark' && {
                        '&:not(.MuiChip-colorPrimary):not(.MuiChip-colorSecondary):not(.MuiChip-colorError):not(.MuiChip-colorInfo):not(.MuiChip-colorSuccess):not(.MuiChip-colorWarning)':
                            {
                                color: colors.turquoise50,
                            },
                    }),
                },
                label: {
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    fontSize: 'inherit',
                },
                sizeSmall: {
                    height: '24px',
                    fontSize: 11,
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 12,
                    },
                    '& .MuiChip-deleteIcon': {
                        fontSize: '16px',
                    },
                },
                sizeMedium: {
                    height: '32px',
                },
                outlined: {
                    ...(mode === 'dark' && {
                        '&:not(.MuiChip-colorPrimary):not(.MuiChip-colorSecondary):not(.MuiChip-colorError):not(.MuiChip-colorInfo):not(.MuiChip-colorSuccess):not(.MuiChip-colorWarning)':
                            {
                                borderColor: alpha(colors.turquoise50, 0.5),
                                '&:hover': {
                                    backgroundColor: alpha(colors.turquoise50, 0.1),
                                },
                            },
                    }),
                },
                filled: {
                    ...(mode === 'dark' && {
                        '&:not(.MuiChip-colorPrimary):not(.MuiChip-colorSecondary):not(.MuiChip-colorError):not(.MuiChip-colorInfo):not(.MuiChip-colorSuccess):not(.MuiChip-colorWarning)':
                            {
                                backgroundColor: alpha(colors.turquoise50, 0.1),
                            },
                    }),
                },
            },
        },
        // table
        MuiTable: {
            styleOverrides: {
                root: {
                    fontFamily: FONT_MONO,
                    fontVariantNumeric: 'slashed-zero tabular-nums',
                    fontSize: 10,
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 13,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {},
                head: {
                    fontWeight: 700,
                    fontSize: 10,
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 13,
                    },
                },
                body: {
                    fontVariantNumeric: 'slashed-zero tabular-nums',
                    fontSize: 10,
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: 13,
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:last-child td, &:last-child th': {border: 0},
                },
            },
        },
        // alert
        MuiAlert: {
            styleOverrides: {
                standardSuccess: {
                    '& .MuiAlert-message': {
                        fontSize: '13px',
                        letterSpacing: '.05rem',
                        whiteSpace: 'pre-line',
                        lineHeight: 1.66,
                        fontWeight: 400,
                    },
                },
                standardInfo: {
                    '& .MuiAlert-message': {
                        fontSize: '13px',
                        letterSpacing: '.05rem',
                        whiteSpace: 'pre-line',
                        lineHeight: 1.66,
                        fontWeight: 400,
                    },
                },
                standardWarning: {
                    '& .MuiAlert-message': {
                        fontSize: '13px',
                        letterSpacing: '.05rem',
                        whiteSpace: 'pre-line',
                        lineHeight: 1.66,
                        fontWeight: 400,
                    },
                },
                standardError: {
                    '& .MuiAlert-message': {
                        fontSize: '13px',
                        letterSpacing: '.05rem',
                        whiteSpace: 'pre-line',
                        lineHeight: 1.66,
                        fontWeight: 400,
                    },
                },
            },
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    '&': {
                        fontSize: '15px',
                        fontWeight: 700,
                    },
                },
            },
        },
        // MuiDataGrid
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '--DataGrid-rowBorderColor': mode === 'light' ? colors.gray80 : alpha(colors.turquoise50, 0.5),
                    '--DataGrid-cellBorderColor': mode === 'light' ? colors.gray80 : alpha(colors.turquoise50, 0.5),
                    '--DataGrid-columnBorderColor': mode === 'light' ? colors.gray80 : alpha(colors.turquoise50, 0.5),
                    fontSize: 14,
                    lineHeight: 1.5,
                    wordWrap: 'break-word',
                    hyphens: 'auto',
                    border: 'none',
                    '& .MuiDataGrid-main': {
                        flex: '1 1 auto',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        minHeight: 'auto !important',
                        maxHeight: '100% !important',
                    },
                    '& .MuiDataGrid-virtualScrollerContent': {
                        height: 'auto !important',
                        maxHeight: 'none !important',
                    },
                    '& .MuiDataGrid-virtualScrollerRenderZone': {
                        height: 'auto !important',
                        maxHeight: 'none !important',
                    },
                    // '& .MuiDataGrid-root': {
                    //     height: '100%',
                    // },
                    // '& .MuiDataGrid-virtualScrollerContent': {
                    //     height: '100% !important', // Force 100% height
                    // },
                    '& .MuiDataGrid-withBorderColor': {
                        borderColor: mode === 'light' ? colors.gray80 : alpha(colors.turquoise50, 0.5),
                    },
                    '& .MuiDataGrid-columnHeader': {
                        borderColor: mode === 'light' ? colors.gray80 : alpha(colors.turquoise50, 0.5),
                    },
                    '& .MuiDataGrid-cell': {
                        lineHeight: 'unset !important',
                        maxHeight: 'none !important',
                        whiteSpace: 'normal',
                        padding: '10px',
                        borderColor: mode === 'light' ? colors.gray80 : alpha(colors.turquoise50, 0.5),
                        '& a': {
                            color: mode === 'light' ? colors.gray50 : colors.gray95,
                            textDecoration: 'none',
                            transition: 'all 0.2s ease',
                            '&:hover': {
                                color: colors.turquoise50,
                            },
                        },
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        color: mode === 'light' ? colors.gray80 : alpha(colors.turquoise50, 0.5),
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderBottomColor: mode === 'light' ? colors.gray80 : alpha(colors.turquoise50, 0.5),
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                    },
                    '& .MuiDataGrid-pinnedColumnHeaders, & .MuiDataGrid-pinnedColumns': {
                        backgroundImage: 'none',
                        boxShadow: 'none',
                    },
                },
            },
        },
    },
});
