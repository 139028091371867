import gql from 'graphql-tag';
import {FRAGMENT_USER_MAIN} from './fragmentsUser';

export const GQ_SEARCH_USERS = gql`
    query searchUsers(
        $term: String
        $state: [UserStateEnum]
        $role: [AuthenticationRoleEnum]
        $external: Boolean
        $freischreiber: Boolean
        $speaker: Boolean
        $first: Int
        $last: Int
        $after: String
        $before: String
        $orderType: UserOrderTypeEnum
        $orderDirection: OrderDirectionEnum
        $ignoreCache: Boolean
        $refreshCache: Boolean
    ) {
        users(
            term: $term
            state: $state
            role: $role
            external: $external
            freischreiber: $freischreiber
            speaker: $speaker
            first: $first
            last: $last
            after: $after
            before: $before
            orderType: $orderType
            orderDirection: $orderDirection
            ignoreCache: $ignoreCache
            refreshCache: $refreshCache
        ) {
            totalCount
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            edges {
                node {
                    ...FragmentUserMain
                    role
                    state
                    dateCreated
                    dateModified
                    url
                    emails(only_main: false, only_public: false) {
                        id
                        email
                        isMain
                        isPublic
                    }
                    login(loginTypes: [USERPASS, OPAC, OPEN_ID]) {
                        type
                        selector
                    }
                    articles(first: 0, state: [PUBLISHED]) {
                        totalCount
                    }
                    profile {
                        purchases {
                            id
                            state
                        }
                        subscriptions {
                            id
                            state
                            dateEnd
                        }
                        flatrates {
                            id
                            state
                            dateEnd
                        }
                    }
                }
            }
        }
    }
    ${FRAGMENT_USER_MAIN}
`;

export const GM_MASQUERADE_USER = gql`
    mutation masqueradeUser($userId: ID!) {
        user {
            masquerade(userId: $userId) {
                backend
                frontend
                unmask
                token
            }
        }
    }
`;

export const GM_DELETE_USER = gql`
    mutation deleteUser($userId: ID!) {
        user {
            update(userId: $userId, input: {state: DELETED}) {
                id
                state
            }
        }
    }
`;
