import {useEffect} from 'react';
import {NetworkError} from '@apollo/client/errors';
import {GraphQLFormattedError} from 'graphql/error';
import {SNACKBAR_AUTO_HIDE_DURATION} from '../constants';
import {useAppDispatch} from '../redux/hooks';
import {setSnackbar} from '../redux/slices/snackbarSlice';

export const useErrorHandler = (
    graphQlErrors: ReadonlyArray<GraphQLFormattedError> | undefined,
    networkError: NetworkError | undefined,
) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (graphQlErrors?.length) {
            dispatch(
                setSnackbar({
                    isOpen: true,
                    severity: 'error',
                    autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION,
                    message: graphQlErrors
                        .map((graphError) => {
                            if ((graphError as any).debugMessage) {
                                return (graphError as any).debugMessage;
                            }
                            return graphError.message;
                        })
                        .join(', '),
                }),
            );
        }
    }, [dispatch, graphQlErrors]);

    useEffect(() => {
        if (networkError) {
            dispatch(
                setSnackbar({
                    isOpen: true,
                    severity: 'error',
                    autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION,
                    message: networkError.message,
                }),
            );
        }
    }, [dispatch, networkError]);
};
