import {Alert as MuiAlert, AlertColor, AlertProps, Box, CircularProgress, Snackbar, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {FieldValues, get} from 'react-hook-form';
import {FieldErrors} from 'react-hook-form/dist/types/errors';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {useAppDispatch, useAppSelector} from '../../lib/redux/hooks';
import {resetSnackbar} from '../../lib/redux/slices/snackbarSlice';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type ErrorSummaryProps<T extends FieldValues> = {
    errors: FieldErrors<T>;
};

function ErrorSummary<T extends FieldValues>({errors}: ErrorSummaryProps<T>) {
    const {t} = useTranslation();
    // log('SnackbarComponent ErrorSummary', errors);

    if (Object.keys(errors).length === 0) {
        return null;
    }

    const ParagraphsErrors: React.FC = () => {
        const paragraphsErrors = get(errors, 'paragraphs');
        return (
            <>
                {paragraphsErrors && (
                    <>
                        <Typography variant={'h4'}>{t('paragraphs.title')}</Typography>
                        <ul>
                            {paragraphsErrors?.map((error: any, index: number) => {
                                return (
                                    <div key={index}>
                                        <li>
                                            <Typography variant={'body2'}>
                                                {t('content.paragraph')}: {index + 1}
                                            </Typography>
                                        </li>

                                        <ul>
                                            {Object.entries(error).map(([key, value]) => {
                                                // paragraphs in items in paragraphs
                                                if (key === 'items') {
                                                    const itemsInParagraphErrors = get(
                                                        errors,
                                                        `paragraphs.[${index}].items`,
                                                    );
                                                    return itemsInParagraphErrors?.map(
                                                        (pInPError: any, pInPIndex: number) => {
                                                            const paragraphsInItemsErrors = get(
                                                                errors,
                                                                `paragraphs.[${index}].items.[${pInPIndex}].paragraphs`,
                                                            );
                                                            return paragraphsInItemsErrors?.map(
                                                                (pInPError: any, pInPIndex: number) => {
                                                                    return Object.entries(pInPError).map(
                                                                        ([pInPKey, pInPValue]) => {
                                                                            return (
                                                                                <div
                                                                                    key={
                                                                                        (pInPValue as any).message +
                                                                                        pInPKey
                                                                                    }
                                                                                >
                                                                                    <li>
                                                                                        <Typography variant={'body2'}>
                                                                                            {t('content.paragraph')}:{' '}
                                                                                            {pInPIndex + 1}
                                                                                        </Typography>
                                                                                    </li>
                                                                                    <ul>
                                                                                        <li>
                                                                                            {pInPKey === 'image'
                                                                                                ? (pInPValue as any).id
                                                                                                      .message
                                                                                                : pInPKey === 'oembed'
                                                                                                  ? (value as any).url
                                                                                                        .message
                                                                                                  : (pInPValue as any)
                                                                                                        .message}
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            );
                                                                        },
                                                                    );
                                                                },
                                                            );
                                                        },
                                                    );
                                                }
                                                // paragraphs in paragraph
                                                if (key === 'paragraphs') {
                                                    const paragraphsInParagraphErrors = get(
                                                        errors,
                                                        `paragraphs.[${index}].paragraphs`,
                                                    );
                                                    return paragraphsInParagraphErrors?.map(
                                                        (pInPError: any, pInPIndex: number) => {
                                                            return Object.entries(pInPError).map(
                                                                ([pInPKey, pInPValue]) => {
                                                                    return (
                                                                        <div key={(pInPValue as any).message + pInPKey}>
                                                                            <li>
                                                                                <Typography variant={'body2'}>
                                                                                    {t('content.paragraph')}:{' '}
                                                                                    {pInPIndex + 1}
                                                                                </Typography>
                                                                            </li>
                                                                            <ul>
                                                                                <li>
                                                                                    {pInPKey === 'image'
                                                                                        ? (pInPValue as any).id.message
                                                                                        : pInPKey === 'oembed'
                                                                                          ? (value as any).url.message
                                                                                          : (pInPValue as any).message}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    );
                                                                },
                                                            );
                                                        },
                                                    );
                                                }
                                                // paragraphs
                                                if (key === 'image') {
                                                    return (
                                                        <li key={key}>
                                                            {key} : {(value as any).id.message}
                                                        </li>
                                                    );
                                                }
                                                if (key === 'oembed') {
                                                    return (
                                                        <li key={key}>
                                                            {key} : {(value as any).url.message}
                                                        </li>
                                                    );
                                                }

                                                return <li key={key}>{(value as any).message}</li>;
                                            })}
                                        </ul>
                                    </div>
                                );
                            })}
                        </ul>
                    </>
                )}
            </>
        );
    };

    interface MessageWithPath {
        path: string;
        message: string;
    }

    const messagesWithPath: MessageWithPath[] = [];
    function getMessagesWithPath(fieldErrorsFragment: object, parentPath = ''): void {
        Object.entries(fieldErrorsFragment).forEach(([key, value]) => {
            const path = parentPath ? `${parentPath}.${key}` : key;
            if (typeof value === 'object') {
                // iterate over objects
                getMessagesWithPath(value, path);
            } else if (key === 'message') {
                // message found
                messagesWithPath.push({path: path, message: value});
            }
        });
    }

    const FieldErrors: React.FC<{
        path: string;
        title: string;
    }> = ({path, title}) => {
        const fieldErrors = get(errors, path);

        // get the error message paths
        if (fieldErrors) {
            getMessagesWithPath(fieldErrors, path);
            return messagesWithPath ? (
                <>
                    <Typography variant={'h4'}>{title}</Typography>
                    <ul>
                        {messagesWithPath.map((messageWithPath: MessageWithPath) => {
                            return <li key={'fieldError.' + messageWithPath.path}>{messageWithPath.message}</li>;
                        })}
                    </ul>
                </>
            ) : null;
        }
        return null;
    };

    const FieldError: React.FC<{
        path: string;
    }> = ({path}) => {
        const fieldError = get(errors, path);
        return fieldError ? <li>{(fieldError as any).message}</li> : null;
    };

    return (
        <>
            {((errors as any).title ||
                (errors as any).locale ||
                (errors as any).subtitle ||
                (errors as any).description ||
                (errors as any).color ||
                (errors as any).team ||
                (errors as any).slug ||
                (errors as any).resorts ||
                (errors as any).icon ||
                (errors as any).logo ||
                (errors as any).hero ||
                (errors as any).image ||
                (errors as any).formats) && (
                <>
                    <Typography variant={'h4'}>{t('structure.contentEditorTabs.baseSettings')}</Typography>
                    <ul>
                        <FieldError path={'title'} />
                        <FieldError path={'locale'} />
                        <FieldError path={'subtitle'} />
                        <FieldError path={'description'} />
                        <FieldError path={'color'} />
                        <FieldError path={'team'} />
                        <FieldError path={'slug'} />
                        <FieldError path={'resorts'} />
                        <FieldError path={'icon.id'} />
                        <FieldError path={'logo.id'} />
                        <FieldError path={'hero.id'} />
                        <FieldError path={'image.id'} />
                        <FieldError path={'formats'} />
                    </ul>
                </>
            )}
            <FieldErrors path={'preview'} title={t('ui.previewLive')} />
            <FieldErrors path={'meta'} title={t('meta.metaData')} />
            <ParagraphsErrors />
        </>
    );
}

type LocalSnackbarStateProps = {
    isOpen?: boolean;
    severity?: AlertColor;
    autoHideDuration?: number | null;
    message?: string | null;
    errors?: FieldErrors | null;
};

export const SnackbarFormComponent: React.FC<{
    isOpen: boolean;
    severity: AlertColor;
    autoHideDuration: number | null;
    message?: string | null;
    errors?: FieldErrors | null;
}> = ({isOpen, severity, autoHideDuration, message, errors}) => {
    const [localSnackbarState, setLocalSnackbarState] = useState<LocalSnackbarStateProps>({
        isOpen: isOpen,
        severity: severity,
        autoHideDuration: autoHideDuration,
        message: message,
        errors: errors,
    });

    const handleSBClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason !== 'clickaway') {
            setLocalSnackbarState((prevState) => ({
                ...prevState,
                isOpen: false,
                autoHideDuration: null,
                message: null,
                errors: null,
            }));
        }
    };

    useEffect(() => {
        if (isOpen) {
            setLocalSnackbarState({
                isOpen: isOpen,
                severity: severity,
                autoHideDuration: autoHideDuration,
                message: message,
                errors: errors,
            });
        }
    }, [isOpen, errors, message, severity, autoHideDuration]);

    return (
        <Snackbar
            open={localSnackbarState.isOpen}
            autoHideDuration={localSnackbarState.autoHideDuration}
            onClose={handleSBClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        >
            <Alert onClose={handleSBClose} severity={localSnackbarState.severity} sx={{width: '100%'}}>
                {localSnackbarState?.errors && <ErrorSummary errors={localSnackbarState?.errors} />}
            </Alert>
        </Snackbar>
    );
};

export const SnackbarGenericComponent: React.FC = () => {
    const snackbarState = useAppSelector((state) => state.snackbar);
    const dispatch = useAppDispatch();
    const [progress, setProgress] = useState(100);
    const messageRef = useRef<string | null>(null);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const [key, setKey] = useState(0); // Add key to force Snackbar remount

    useEffect(() => {
        // Clear existing timer if any
        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }

        // Start new timer if snackbar is open and message changed
        if (snackbarState.isOpen && snackbarState.message !== messageRef.current) {
            messageRef.current = snackbarState.message || null;
            setProgress(100);
            // Force remount of Snackbar to reset its internal timer
            setKey((prev) => prev + 1);

            const duration = snackbarState.autoHideDuration || 120000;
            const startTime = Date.now();

            timerRef.current = setInterval(() => {
                const elapsed = Date.now() - startTime;
                const remaining = Math.max(0, 100 - (elapsed / duration) * 100);
                setProgress(remaining);

                if (remaining <= 0) {
                    if (timerRef.current) {
                        clearInterval(timerRef.current);
                        timerRef.current = null;
                    }
                }
            }, 100);
        }

        // Reset when snackbar closes
        if (!snackbarState.isOpen) {
            messageRef.current = null;
        }

        // Cleanup
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
                timerRef.current = null;
            }
        };
    }, [snackbarState.isOpen, snackbarState.message, snackbarState.autoHideDuration]);

    const handleSBClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(resetSnackbar());
    };

    const formatMessage = (message: string) => {
        // First split by line breaks
        return message.split(' | ').map((segment, segmentIndex) => {
            // For each segment, process bold and italic
            const parts = segment.split(/(\*\*.*?\*\*|\*.*?\*)/g);

            const formattedParts = parts.map((part, i) => {
                if (part.startsWith('**') && part.endsWith('**')) {
                    // Bold text
                    return <strong key={`bold-${i}`}>{part.slice(2, -2)}</strong>;
                } else if (part.startsWith('*') && part.endsWith('*')) {
                    // Italic text
                    return <em key={`italic-${i}`}>{part.slice(1, -1)}</em>;
                }
                return part;
            });

            return (
                <React.Fragment key={`segment-${segmentIndex}`}>
                    {segmentIndex > 0 && <br />}
                    {formattedParts}
                </React.Fragment>
            );
        });
    };

    return (
        <Snackbar
            key={key} // Add key to force remount
            open={snackbarState.isOpen}
            autoHideDuration={snackbarState.autoHideDuration}
            onClose={handleSBClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        >
            <Alert
                severity={snackbarState.severity}
                sx={{
                    width: '100%',
                    '& .MuiAlert-action': {
                        padding: '0 8px',
                        alignItems: 'center',
                        marginTop: 0,
                        paddingTop: 0,
                    },
                }}
                action={
                    <Box
                        onClick={handleSBClose}
                        sx={{
                            position: 'relative',
                            width: 20,
                            height: 20,
                            cursor: 'pointer',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center',
                            my: 'auto',
                        }}
                    >
                        <CircularProgress
                            variant="determinate"
                            value={progress}
                            size={20}
                            sx={{
                                position: 'absolute',
                                color: 'inherit',
                                opacity: 0.5,
                                '& .MuiCircularProgress-circle': {
                                    strokeLinecap: 'round',
                                    transition: 'none',
                                },
                            }}
                        />
                        <CloseIcon
                            sx={{
                                fontSize: 16,
                                zIndex: 1,
                            }}
                        />
                    </Box>
                }
            >
                {formatMessage(snackbarState.message ?? '')}
            </Alert>
        </Snackbar>
    );
};
