import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EditPaymentDataState} from '../../../types/redux';

const initialState: EditPaymentDataState = {
    isOpen: false,
    payment: null,
    paymentIndex: null,
    refetchQuery: null,
};

export const editPaymentDataSlice = createSlice({
    name: 'editPaymentData',
    initialState,
    reducers: {
        setPaymentData: (state, action: PayloadAction<EditPaymentDataState>) => {
            return {...action.payload};
        },
        resetPaymentData: () => {
            return initialState;
        },
    },
});

// Export the action creators
export const {setPaymentData, resetPaymentData} = editPaymentDataSlice.actions;

// Export the reducer
export default editPaymentDataSlice.reducer;
