import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Outlet} from 'react-router-dom';
import {AppLayout} from './components/structure/AppLayout';
import {Preloader} from './components/structure/Preloader';
import {AppState, UserState, UserStatus} from './types/redux';
import {NetworkError} from '@apollo/client/errors';
import {GraphQLFormattedError} from 'graphql/error';
import {useAuth} from './lib/hooks/useAuth';
import {useErrorHandler} from './lib/hooks/useErrorHandler';
import {useLocation, useNavigate} from 'react-router';
import {ROUTE_ARTICLES_LANG, ROUTE_LOGIN, ROUTE_LOGOUT} from './lib/utils/router';
import {loginAttempt} from './lib/redux/slices/userSlice';
import {Location} from 'history';
import {useAppDispatch} from './lib/redux/hooks';

interface InternalAppProps {
    setNewAuthToken: (token: string | null) => void;
    graphQlErrors: ReadonlyArray<GraphQLFormattedError> | undefined;
    networkError: NetworkError | undefined;
}

export const InternalApp: React.FC<InternalAppProps> = ({setNewAuthToken, graphQlErrors, networkError}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const user = useSelector<AppState, UserState>((state) => state.user);
    const {isLoading, error, checkAuth, loginWithToken, logout} = useAuth(setNewAuthToken);

    const pageIsLogin = location.pathname.replace(/\/$/, '') === ROUTE_LOGIN.replace(/\/$/, '');
    const pageIsLogout = location.pathname.replace(/\/$/, '') === ROUTE_LOGOUT.replace(/\/$/, '');
    const pageIsProtected = !pageIsLogin && !pageIsLogout;
    const isLoggedIn = user.status === UserStatus.LOGGED_IN;

    // Handle GraphQL and network errors
    useErrorHandler(graphQlErrors, networkError);

    // Handle auth redirects
    useEffect(() => {
        // If a user is logged in and tries to access the login page
        if (isLoggedIn && pageIsLogin) {
            navigate({pathname: `${ROUTE_ARTICLES_LANG}/de`});
            return;
        }
    }, [dispatch, isLoggedIn, location.pathname, navigate, pageIsLogin, pageIsProtected, user.redirectLocation]);

    // Handle auth state changes
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const queryToken = queryParams.get('token');

        if (queryToken) {
            // Handle token login
            const url = new URL(window.location.href);
            url.searchParams.delete('token');
            window.history.replaceState({}, '', url.toString());
            loginWithToken(queryToken);
        } else {
            // Handle protected route access
            if (user.status === UserStatus.ANONYMOUS || user.status === UserStatus.LOGIN_IN_PROGRESS) {
                const locationObj: Location = {
                    pathname: location.pathname,
                    search: '',
                    hash: '',
                    state: null,
                    key: '',
                };
                dispatch(loginAttempt(locationObj));
                checkAuth();
            } else if (user.status === UserStatus.SESSION_EXPIRED || user.status === UserStatus.LOGGED_OUT) {
                // Only navigate if we're not already on login/logout pages
                if (location.pathname !== ROUTE_LOGIN && location.pathname !== ROUTE_LOGOUT) {
                    navigate(ROUTE_LOGIN);
                }
            }
        }
    }, [user.status, checkAuth, loginWithToken, logout, location.pathname]);

    if (error) {
        return (
            <AppLayout>
                <h2>{error.message}</h2>
            </AppLayout>
        );
    }

    if (isLoading || (pageIsProtected && !isLoggedIn)) {
        return (
            <AppLayout>
                <Preloader />
            </AppLayout>
        );
    }

    return (
        <AppLayout hideNavigation={!pageIsProtected}>
            <React.Suspense fallback={<Preloader />}>
                <Outlet context={{setNewAuthToken}} />
            </React.Suspense>
        </AppLayout>
    );
};
