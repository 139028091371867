import {configureStore} from '@reduxjs/toolkit';
import {loadSessionData, saveSessionData} from '../utils/storage';
import {SESSION_KEY_APP_STATE} from '../constants';
import {AppState} from '../../types/redux';
import {rootReducer} from './index';
import type {RootState} from './index';

let clientStore: ReturnType<typeof setupStore> | null = null;

export function setupStore(preloadedState?: Partial<AppState>) {
    const store = configureStore({
        reducer: rootReducer,
        preloadedState,
        // DevTools are enabled by default in development
    });

    // Subscribe to store changes to save to session storage
    store.subscribe(() => {
        saveSessionData(SESSION_KEY_APP_STATE, store.getState());
    });

    // Enable hot module replacement for reducers
    if (process.env.NODE_ENV !== 'production') {
        // Vite-compatible Hot Module Replacement (HMR)
        if (import.meta.hot) {
            import.meta.hot.accept(import.meta.resolve('./reducers.ts'), async (newModule) => {
                if (!newModule) return;
                const newRootReducer = newModule.default;
                store.replaceReducer(newRootReducer);
            });
        }
    }

    return store;
}

// Initialize store with any persisted state
export const initializeStore = (preloadedState?: Partial<AppState>) => {
    // Return existing store if available
    if (clientStore) {
        return clientStore;
    }

    // Get persisted state from session storage
    const persistedState = loadSessionData(SESSION_KEY_APP_STATE) || {};

    // Create store with merged state
    const store = setupStore({
        ...(preloadedState || {}),
        ...(persistedState || {}),
    });

    // Save store for future reference
    clientStore = store;

    return store;
};

// Export convenience types for components
export type AppDispatch = ReturnType<typeof setupStore>['dispatch'];

// Re-export RootState for use throughout the app
export type {RootState};
