import React, {memo, useEffect, useState} from 'react';
import {Box, Button, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import {ROUTE_HOME, ROUTE_LOGIN} from '../lib/utils/router';
import {useTranslation} from 'react-i18next';
import {Link, NavLink} from 'react-router-dom';
import {AppState, UserState, UserStatus} from '../types/redux';
import {useSelector} from 'react-redux';
import {LogoRIcon} from '../assets/UiIcons';
import {useAuth} from '../lib/hooks/useAuth';
import {loadLocalData} from '../lib/utils/storage';
import {LOCAL_KEY_TOKEN} from '../lib/constants';
import {log} from '../lib/utils/logger';

export const LogoutPage: React.FC = () => {
    const {t} = useTranslation();

    const user: UserState = useSelector<AppState, UserState>((state) => state.user);

    const [authToken, setAuthToken] = useState(loadLocalData(LOCAL_KEY_TOKEN));
    const {logout} = useAuth(setAuthToken);

    const MemoizedLogoRIcon = memo(LogoRIcon);

    useEffect(() => {
        logout().then(() => {
            log('logout successful');
        });
    }, [logout]);

    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: 'calc(100vh)',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grid container justifyContent={'center'} sx={{width: '100vw'}}>
                <Grid size={{xs: 10, sm: 8, lg: 6}}>
                    <Box display={'flex'} alignContent={'center'} justifyContent={'center'}>
                        <NavLink to={ROUTE_HOME}>
                            <MemoizedLogoRIcon color={'primary'} sx={{mb: 6}} />
                        </NavLink>
                    </Box>
                    {authToken ? (
                        <Typography variant={'h2'} textAlign={'center'}>
                            {t('logout.process')}
                        </Typography>
                    ) : (
                        <>
                            <Typography variant={'h2'} my={3} textAlign={'center'}>
                                {user.status === UserStatus.SESSION_EXPIRED
                                    ? t('logout.sessionExpired.title')
                                    : t('logout.manual.title')}
                            </Typography>
                            <Typography variant={'h3'} textAlign={'center'}>
                                {user.status === UserStatus.SESSION_EXPIRED
                                    ? t('logout.sessionExpired.subtitle')
                                    : t('logout.manual.subtitle')}
                            </Typography>
                            <Grid size={12} sx={{my: 6, textAlign: 'center'}}>
                                <Button
                                    component={Link}
                                    to={ROUTE_LOGIN}
                                    variant={'contained'}
                                    color={'primary'}
                                    fullWidth={false}
                                >
                                    {t('logout.login')}
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
