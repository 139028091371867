import React, {createContext, useContext, useCallback} from 'react';

type TitleContextType = {
    setTitle: (title: string | null) => void;
};

const TitleContext = createContext<TitleContextType | undefined>(undefined);

export const TitleProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const setTitle = useCallback((title: string | null) => {
        document.title = title ? `${title} | RiffReporter` : 'RiffReporter';
    }, []);

    return <TitleContext.Provider value={{setTitle}}>{children}</TitleContext.Provider>;
};

export const useTitle = () => {
    const context = useContext(TitleContext);
    if (!context) {
        throw new Error('useTitle must be used within a TitleProvider');
    }
    return context;
};
