export const SESSION_KEY_APP_STATE = 'appState';
export const LOCAL_KEY_TOKEN = 'token';
export const LOCAL_KEY_UNMASK = 'unmask';

/**
 * LocalStorage keys
 */
export const LOCALSTORAGE_PARAGRAPHS_STATE_PREFIX = 'paragraphs_state_';

/**
 * IndexedDB stores
 */
export const INDEXED_STORE_ARTICLES = 'articles';
export const INDEXED_STORE_PAGES = 'pages';
export const INDEXED_STORES_CONFIG = [
    {name: INDEXED_STORE_ARTICLES, options: {}},
    {name: INDEXED_STORE_PAGES, options: {}},
    // add other object store configurations if needed
];
export const INDEXED_STORE_VERSION = 2;

/**
 * The supported languages of this app
 */
export enum LanguageEnum {
    DE = 'de',
    EN = 'en',
}

/**
 * Search parameter for all search functions
 */
export const MINIMUM_CHARACTERS_TO_START_SEARCH = 2;
export const DEBOUNCE_TIME_TO_START_SEARCH = 1000;
export const DEBOUNCE_FORM_INPUT = 1000;

/**
 * Global time to keep feedback snackbars open
 */
export const SNACKBAR_AUTO_HIDE_DURATION = 6000;
