import React, {useEffect} from 'react';
import {createTheme, responsiveFontSizes, ThemeOptions, ThemeProvider, useMediaQuery} from '@mui/material';
import {getThemeOptions} from '../styles/theme';
import {setTheme} from '../lib/redux/slices/themeSlice';
import {useAppDispatch, useAppSelector} from '../lib/redux/hooks';

type ThemeMode = 'light' | 'dark';

export const ThemeSwitcher: React.FC<{children: React.ReactNode}> = ({children}) => {
    const dispatch = useAppDispatch();
    const riffThemeIsUserPreference = useAppSelector((state) => state.theme.isUserPreference);
    const riffThemeMode = useAppSelector((state) => (state.theme.mode as ThemeMode) || 'light');
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const riffTheme = React.useMemo(
        () => responsiveFontSizes(createTheme(getThemeOptions(riffThemeMode) as ThemeOptions)),
        [riffThemeMode],
    );

    useEffect(() => {
        if (riffThemeIsUserPreference === false && prefersDarkMode) {
            dispatch(
                setTheme({
                    mode: 'dark',
                    isUserPreference: true,
                }),
            );
        }
    }, [prefersDarkMode, riffThemeIsUserPreference, dispatch]);

    return <ThemeProvider theme={riffTheme}>{children}</ThemeProvider>;
};
