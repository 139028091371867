// src/components/functional/Masquerade.tsx
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DialogContent, Button, Typography, ButtonGroup} from '@mui/material';
import {AuthenticationRoleEnum} from '../../types/graphqlTypes';
import {ApolloError, useMutation} from '@apollo/client';
import {err} from '../../lib/utils/logger';
import {GM_MASQUERADE_USER} from '../../lib/services/polypublisher/gql/users';
import {StyledDialog} from '../structure/Dialogs';
import {LOCAL_KEY_UNMASK} from '../../lib/constants';

interface UnmaskData {
    unmaskToken?: string;
    unmaskUrl?: string;
    returnUrl?: string;
}

const executeMasquerade = (masqueradeUser: any, userId: string, isBackendRequested: boolean) => {
    return masqueradeUser({variables: {userId}})
        .then((res: any) => {
            if (res.data.user.masquerade.unmask) {
                // Store both unmask and current URL
                const url = new URL(window.location.href);
                const unmaskData: UnmaskData = {
                    unmaskToken: res.data.user.masquerade?.token ?? null,
                    unmaskUrl: res.data.user.masquerade?.unmask ?? null,
                    returnUrl: `${url.origin}${url.pathname}`,
                };
                localStorage.setItem(LOCAL_KEY_UNMASK, JSON.stringify(unmaskData));
            }
            if (isBackendRequested) {
                if (res.data.user.masquerade.backend) {
                    window.location.href = res.data.user.masquerade.backend;
                }
            } else {
                if (res.data.user.masquerade.frontend) {
                    window.open(res.data.user.masquerade.frontend, '_blank');
                }
            }
        })
        .catch((e: ApolloError) => {
            err('masquerade user error', e);
        });
};

interface MasqueradeDialogProps {
    open: boolean;
    onClose: () => void;
    userId: string;
}

export const MasqueradeDialog: React.FC<MasqueradeDialogProps> = ({open, onClose, userId}) => {
    const {t} = useTranslation();

    const [masqueradeUser] = useMutation(GM_MASQUERADE_USER);

    const handleBackendMasquerade = () => {
        executeMasquerade(masqueradeUser, userId, true);
        onClose();
    };

    const handleFrontendMasquerade = () => {
        executeMasquerade(masqueradeUser, userId, false);
        onClose();
    };

    return (
        <StyledDialog isOpen={open} handleClose={onClose} disableEscapeKeyDown={false} title={t('ui.masquerade.title')}>
            <DialogContent dividers={true}>
                <Typography variant={'body2'} color={'text.secondary'}>
                    {t('ui.masquerade.copy')}
                </Typography>
                <ButtonGroup
                    variant={'outlined'}
                    color={'primary'}
                    orientation={'vertical'}
                    size={'large'}
                    fullWidth={true}
                    disableElevation
                    sx={{mt: 3, mb: 3}}
                >
                    <Button onClick={handleFrontendMasquerade}>{t('ui.masquerade.frontend')}</Button>
                    <Button onClick={handleBackendMasquerade}>{t('ui.masquerade.backend')}</Button>
                </ButtonGroup>
            </DialogContent>
        </StyledDialog>
    );
};

export const useMasquerade = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [pendingMasquerade, setPendingMasquerade] = useState<{
        id: string;
    } | null>(null);
    const [masqueradeUser] = useMutation(GM_MASQUERADE_USER);

    const handleMasquerade = (id: string, role: AuthenticationRoleEnum) => {
        if (
            role === AuthenticationRoleEnum.Root ||
            role === AuthenticationRoleEnum.Administrator ||
            role === AuthenticationRoleEnum.Editor
        ) {
            setPendingMasquerade({id});
            setDialogOpen(true);
        } else {
            // For non-privileged roles, directly execute frontend masquerade
            executeMasquerade(masqueradeUser, id, false);
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setPendingMasquerade(null);
    };

    return {
        handleMasquerade: handleMasquerade,
        MasqueradeDialogComponent: pendingMasquerade ? (
            <MasqueradeDialog open={dialogOpen} onClose={handleDialogClose} userId={pendingMasquerade.id} />
        ) : null,
    };
};

export const useUnmasquerade = () => {
    // Get data directly from localStorage instead of using loadLocalData
    const unmaskDataString = localStorage.getItem(LOCAL_KEY_UNMASK);

    // Only remove and process if we have data
    if (unmaskDataString) {
        localStorage.removeItem(LOCAL_KEY_UNMASK);

        try {
            const unmaskData: UnmaskData = JSON.parse(unmaskDataString);

            if (unmaskData.returnUrl && unmaskData.unmaskToken) {
                window.location.href = `${unmaskData.returnUrl}?token=${unmaskData.unmaskToken}`;
            } else if (unmaskData.unmaskUrl) {
                window.location.href = unmaskData.unmaskUrl;
            } else {
                window.location.href = '/';
            }
        } catch (e) {
            err('Error parsing unmask data:', e);
            window.location.href = '/';
        }
    }
};
