import {gql} from '@apollo/client';

export const FRAGMENT_IMAGE = gql`
    fragment FragmentImage on Image {
        id
        locale
        alias
        alt
        orientation
        caption
        focalX
        focalY
        copyright {
            name
            url
            email
            phone
            address
            note
        }
        usagerights {
            socialmedia
            socialmediaNote
            marketplace
            marketplaceNote
            price
            singleUse
            dateExpiry
        }
        placeholder {
            url
            width
            height
        }
        src {
            url
            width
            height
            mime
        }
    }
`;

export const FRAGMENT_IMAGE_SRC = gql`
    fragment FragmentImageSrc on Image {
        id: cacheId
        src {
            url
        }
    }
`;

export const FRAGMENT_ICON = gql`
    fragment FragmentIcon on Image {
        id: cacheId
        src {
            data
        }
    }
`;

export const FRAGMENT_IMAGE_SRC_ALIASES = gql`
    fragment FragmentImageSrcAliases on Image {
        thumbnail: src(width: 256, ratio: ORIGINAL) {
            url
        }
        preview: src(width: 480, ratio: ORIGINAL) {
            url
            width
            height
            mime
        }
        original: src(width: 10000, ratio: ORIGINAL) {
            url
            width
            height
        }
    }
`;
