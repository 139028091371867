import {ApolloClient, ApolloLink, Observable} from '@apollo/client';
import {InMemoryCache} from '@apollo/client/cache';
import {NetworkError} from '@apollo/client/errors';
import {setContext} from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import {GraphQLFormattedError} from 'graphql/error';
import {Store} from 'redux';
import {createErrorLink} from './apolloErrorHandling';
import {useEffect, useState} from 'react';
import {loadLocalData} from './utils/storage';
import {LOCAL_KEY_TOKEN} from './constants';

// Type for the abort controller map
const pendingRequests = new Map<string, AbortController>();

// Create a custom upload link that supports request aborting
const createAbortableUploadLink = (authToken: string | null) => {
    return createUploadLink({
        uri: import.meta.env.VITE_POLYPUBLISHER_GRAPH_URL,
        credentials: 'same-origin',
        headers: {
            Authorization: authToken ? `${authToken}` : '',
            PolyPlatform: import.meta.env.VITE_POLYPUBLISHER_PLATFORM || 'RIFFREPORTER',
            ClientIP: '',
            ClientUserAgent: '',
        },
        fetch: async (input: RequestInfo | URL, init?: RequestInit) => {
            const controller = new AbortController();
            const requestId = Math.random().toString(36).substring(7);

            pendingRequests.set(requestId, controller);

            try {
                return await fetch(input, {
                    ...init,
                    signal: controller.signal,
                });
            } finally {
                pendingRequests.delete(requestId);
            }
        },
    }) as unknown as ApolloLink;
};

// Add navigation handling link
const createNavigationLink = () => {
    return new ApolloLink((operation, forward) => {
        return new Observable((observer) => {
            const subscription = forward(operation).subscribe({
                next: (result) => observer.next(result),
                error: (error) => {
                    // Check if the error is due to an aborted request
                    if (error.name === 'AbortError') {
                        // Complete the stream instead of erroring
                        observer.complete();
                    } else {
                        observer.error(error);
                    }
                },
                complete: () => observer.complete(),
            });

            return () => {
                subscription.unsubscribe();
            };
        });
    });
};

interface CreateApolloClientProps {
    authToken: string | null;
    store: Store;
    setGraphQlErrors: (errors: ReadonlyArray<GraphQLFormattedError> | undefined) => void;
    setNetworkError: (error: NetworkError | undefined) => void;
}

export const createApolloClient = ({authToken, store, setGraphQlErrors, setNetworkError}: CreateApolloClientProps) => {
    const authLink = setContext((_, {headers}) => {
        const token = loadLocalData(LOCAL_KEY_TOKEN);
        return {
            headers: {
                ...headers,
                Authorization: token ? `${token}` : '',
            },
        };
    });

    const uploadLink = createAbortableUploadLink(authToken);
    const errorLink = createErrorLink(store, setGraphQlErrors, setNetworkError);
    const navigationLink = createNavigationLink();

    return new ApolloClient({
        link: ApolloLink.from([errorLink, navigationLink, authLink, uploadLink]),
        cache: new InMemoryCache({
            typePolicies: {
                // Image: {
                //   fields: {
                //     src: { merge: true },
                //     picture: { merge: true },
                //   },
                // },
            },
        }),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        },
    });
};

interface UseApolloClientProps {
    authToken: string | null;
    store: Store;
}

export const useApolloClient = ({authToken, store}: UseApolloClientProps) => {
    const [graphQlErrors, setGraphQlErrors] = useState<ReadonlyArray<GraphQLFormattedError> | undefined>(undefined);
    const [networkError, setNetworkError] = useState<NetworkError | undefined>(undefined);

    const [apolloClient, setApolloClient] = useState(() => {
        return createApolloClient({
            authToken,
            store,
            setGraphQlErrors,
            setNetworkError,
        });
    });

    useEffect(() => {
        setApolloClient(
            createApolloClient({
                authToken,
                store,
                setGraphQlErrors,
                setNetworkError,
            }),
        );

        // Cleanup function to abort any pending requests on unmount or auth change
        return () => {
            pendingRequests.forEach((controller) => {
                controller.abort();
            });
            pendingRequests.clear();
        };
    }, [authToken, store]);

    return {
        apolloClient,
        graphQlErrors,
        networkError,
    };
};

export const createAuthLink = (authToken: string | null) =>
    setContext((_, {headers}) => {
        // Fetch the latest token directly from storage
        if (authToken === null) {
            authToken = loadLocalData(LOCAL_KEY_TOKEN);
        }
        return {
            headers: {
                ...headers,
                Authorization: authToken ? `${authToken}` : '',
            },
        };
    });
