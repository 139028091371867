import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SnackbarState} from '../../../types/redux';
import {SNACKBAR_AUTO_HIDE_DURATION} from '../../constants';

const initialState: SnackbarState = {
    isOpen: false,
    severity: 'error',
    autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION,
    message: null,
    errors: null,
};

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        setSnackbar: (state, action: PayloadAction<SnackbarState>) => {
            return {...action.payload};
        },
        resetSnackbar: (state) => {
            // Keep the current severity but reset everything else
            return {
                ...initialState,
                severity: state.severity,
            };
        },
    },
});

// Export the action creators
export const {setSnackbar, resetSnackbar} = snackbarSlice.actions;

// Export the reducer
export default snackbarSlice.reducer;
