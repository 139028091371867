import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UploadFileState} from '../../../types/redux';

const initialState: UploadFileState = {
    isOpen: false,
    isMediaFile: false,
};

export const uploadFileSlice = createSlice({
    name: 'uploadFile',
    initialState,
    reducers: {
        setFileUpload: (state, action: PayloadAction<UploadFileState>) => {
            return {...action.payload};
        },
        resetFileUpload: () => {
            return initialState;
        },
    },
});

// Export the action creators
export const {setFileUpload, resetFileUpload} = uploadFileSlice.actions;

// Export the reducer
export default uploadFileSlice.reducer;
